import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  .ant-layout {
    height: 100%;
    background: #ebebeb;
  }
  .demo-logo-vertical {
    padding: 5px 10px 0px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 16px 15px 16px;
    background: rgb(255 255 255 / 0%);
    border-radius: 6px;
    border: 1px solid #ffffff29;
    mix-blend-mode: darken;
  }
`;

import { Breadcrumb, Layout } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  DefaultAdminPage,
  DefaultUserPage,
  FormatUserName,
} from "../../../config";
import { BackToAdminWrapper } from "./style";

const { Header } = Layout;
const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};

export default function HeaderView({
  colorBgContainer,
  collapsed,
  setCollapsed,
  user,
  setData,
  location,
  handleLogout,
}) {
  const navigate = useNavigate();

  return (
    <Header
      className="slideInDown"
      style={{
        padding: 0,
        background: "#FFF",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "15px",
        marginTop: "5px",
        borderRadius: "12px",
        marginRight: "15px",

        position: "sticky",
        top: 0,
        zIndex: 9,
        transition: "box-shadow .25s ease-in,background-color .25s ease-in",
        // backdropFilter: "saturate(87%) blur(20px)",
        boxShadow:
          "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="d-flex ms-4">
          <Breadcrumb
            separator={"/"}
            items={[
              {
                title: "Pages",
              },
              ...location?.pathname
                ?.split("/")
                ?.filter((d) => d)
                ?.map((d) => ({
                  title: FormatUserName(d),
                })),
            ]}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        {user?.admin && (
          <div
            className="d-flex align-items-center align-items-stretch mx-1"
            style={{
              height: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BackToAdminWrapper
              onClick={() => {
                setData(user?.admin);
                navigate(findRoutes?.[user?.admin?.loginType]);
              }}
            >
              Back to Admin
            </BackToAdminWrapper>
          </div>
        )}
      </div>
    </Header>
  );
}

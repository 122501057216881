import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MakeApiCall } from "../../../apis";
import {
  DefaultAdminPage,
  DefaultUserPage,
  primaryColor,
  secondaryColor,
  thirdColor,
  Types,
} from "../../../config";
import { setData } from "../../../redux/actions";

const WrapperSignIn = styled.div`
  height: 100%;
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  .login {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(
        135deg,
        transparent 0%,
        transparent 26%,
        ${thirdColor} 26%,
        ${thirdColor} 72%,
        transparent 72%,
        transparent 82%,
        ${primaryColor} 82%,
        ${primaryColor} 100%
      ),
      linear-gradient(
        45deg,
        transparent 0%,
        transparent 4%,
        ${primaryColor} 4%,
        ${primaryColor} 22%,
        ${secondaryColor} 22%,
        ${secondaryColor} 60%,
        transparent 60%,
        transparent 100%
      ),
      linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
    position: relative;
  }

  .login_box {
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #f1f1f4;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 2.5rem;
    .text-gray-500 {
      color: #78829d;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      margin-inline-end: 0.375rem;
      text-align: center;
      margin-bottom: 1.3rem;
    }
    .fw-bolder {
      text-align: center;
      margin-bottom: 1rem;

      color: #071437;
      font-size: 1.125rem;
      font-weight: 600;
    }
  }
  .sign-up-link {
    span {
      color: #78829d;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      margin-inline-end: 0.375rem;
    }
    a {
      color: #1b84ff;
      font-size: 0.8125rem;
      text-decoration: inherit;
    }
  }
`;

const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};

export default function SignIn() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleLogin = (data) => {
    const type = Types?.[data?.data?.user_data?.user_type];
    const obj = {
      user: data?.data,
      loginType: type,
      token: data?.data?.auth_token,
    };

    dispatch(setData(obj));
    navigate(findRoutes?.[type]);
  };

  useEffect(() => {
    if (Object.keys(user)?.length !== 0) {
      setLoading(false);
      navigate(findRoutes?.[user.loginType]);
    }
    return () => {};
  }, []);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const response = await MakeApiCall("admin-login", "post", { ...values });

    if (response?.status === true) {
      handleLogin(response);
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <WrapperSignIn>
      <section className="login">
        <div className="login_box fadeInDown">
          <div className="left">
            <div className="text-center mb-11">
              {/*begin::Title*/}
              <h1 className="text-gray-900 fw-bolder mb-3">
                <h1 className="tracking-in-expand-fwd  fw-bold fs-2x mb-3">
                  Welcome in <span className="text-primary">AalaCart</span>
                </h1>
              </h1>

              <div className="text-gray-500 fw-semibold fs-6">
                Please enter your account details.
              </div>
            </div>

            <div className="contact">
              <Form
                name="signin"
                style={{ marginTop: "40px" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      background: "rgb(243 243 243)",
                      borderRadius: 6,
                      fontSize: "12px",
                    }}
                    size="large"
                    variant="filled"
                    placeholder="Username"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{
                      background: "rgb(243 243 243)",
                      borderRadius: 6,
                      fontSize: "12px",
                    }}
                    size="large"
                    variant="filled"
                    placeholder="Password"
                  />
                </Form.Item>
                <div
                  className="d-flex justify-content-end"
                  style={{ margin: "20px 0px 10px" }}
                >
                  <Link>Forgot Password?</Link>
                </div>
                <Form.Item>
                  <Button
                    loading={submitLoading}
                    style={{ width: "100%" }}
                    htmlType="submit"
                    type="primary"
                    size="middle"
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
              <div className="sign-up-link d-flex justify-content-center">
                <span className="">Need an account?</span>
                <Link className="" to="/sign-up">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WrapperSignIn>
  );
}

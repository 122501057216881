import styled from "styled-components";
import { forthColor } from "../../../../../config";

export const Wrapper = styled.div`
  .ant-segmented .ant-segmented-item-selected {
    background-color: ${forthColor} !important;
  }
  .ant-segmented .ant-segmented-item:active {
    background-color: ${forthColor} !important;
  }
  .ant-segmented .ant-segmented-item:hover {
    background-color: ${forthColor} !important;
  }
`;

import styled from "styled-components";
import { secondaryColor } from "../../config";

export const Table1Wrapper = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      background: #f1f1f1 !important;
      color: #252f4a !important;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 2px dashed #f0f0f0;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-size: 12px !important;
  }
`;
export const Table2Wrapper = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: #f1f1f1 !important;

    color: #25304a !important;
    font-weight: 700 !important;
    border-bottom: none !important;
    font-size: 10px !important;
    border-radius: 0px !important;
    border-bottom: 5px solid white !important;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-size: 12px !important;
  }
  .ant-table-row {
    .ant-table-cell {
      border-bottom: 5px solid white !important;
    }
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent !important;
  }
  .ant-table-tbody {
    .ant-table-cell {
      background: #f9f9f9 !important;
    }
  }
  .ant-table-wrapper .ant-table-cell,
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 5px 16px 5px 16px !important;
  }
  .ant-table-row > td:first-child::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 2px;
    height: 20px;
    background-color: ${secondaryColor} !important;

    bottom: 0;
    margin: auto;
  }

  .ant-table-row {
    .ant-table-cell {
      font-size: 13px !important;
      color: #071437 !important;
    }
  }
`;

import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  message,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Table,
  Typography,
} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { DeleteIcon } from "../../../../../../../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import TextArea from "antd/es/input/TextArea";
import Table2 from "../../../../../../../../../components/tables/table2";
const { Text } = Typography;

const NegativeKeywordTargeting = () => {
  const [matchType, setMatchType] = useState("negative_exact");
  const [keyword, setKeyword] = useState("");
  const [selectedNegativeKeywords, setSelectedNegativeKeywords] = useState([]);

  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
      render: (match_type) => (
        <span style={{ textTransform: "capitalize" }}>
          {match_type?.toLowerCase().replaceAll("_", " ")}
        </span>
      ),
    },
    {
      title: "Action",
      render: (record, _, index) => (
        <Popconfirm
          title="Are you sure you want to delete this keyword?"
          onConfirm={() => {
            const updatedList = selectedNegativeKeywords.filter(
              (item, ki) => ki !== index
            );
            setSelectedNegativeKeywords(updatedList);
          }}
          okText="Yes"
          cancelText="No"
        >
          <span>
            <DeleteIcon />
          </span>
        </Popconfirm>
      ), // Customize action with buttons
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              Negative Keyword Targeting(Optional)
            </Title>
          }
        >
          <Row>
            <Col span={24} className="mt-5">
              <Text strong>Match Type</Text>
              <Radio.Group
                name="Match Type"
                className="ms-3"
                onChange={(e) => {
                  setMatchType(e.target.value);
                }}
                value={matchType}
              >
                <Radio value={"negative_exact"}>Negative Exact</Radio>
                <Radio value={"negative_phrase"}>Negative Phrase</Radio>
              </Radio.Group>
              <Divider variant="dashed" className="my-2" />
              <TextArea
                className="mt-2"
                rows={6}
                placeholder="Enter Keyword"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
              <Button
                type="primary"
                className="mt-3"
                disabled={!keyword}
                onClick={() => {
                  if (keyword) {
                    const check_keyword = selectedNegativeKeywords?.filter(
                      (item) =>
                        item?.keyword === keyword?.trim() &&
                        item?.match_type === matchType
                    );
                    if (check_keyword?.length > 0) {
                      message.destroy();
                      message.info("Keyword Exists");
                      setKeyword("");
                      return false;
                    }
                    setSelectedNegativeKeywords([
                      ...selectedNegativeKeywords,
                      {
                        match_type: matchType,
                        state: "ENABLED",
                        keyword: keyword,
                      },
                    ]);
                    setKeyword("");
                  }
                }}
              >
                Add Keyword
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              Selected Keywords
            </Title>
          }
        >
          <Row>
            <Col span={24}>
              <Table2
                dataSource={selectedNegativeKeywords}
                columns={columns}
                pagination={false}
                scroll={{ y: 260 }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default NegativeKeywordTargeting;

import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Image, Input, message, Popconfirm } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { AddBTN } from "../../../components/button";
import Table2 from "../../../components/tables/table2";
import {
  AddCommas,
  ConvertParams,
  DateFormate,
  DefaultAdminPage,
  DefaultUserPage,
  DeleteIcon,
  EditIcon,
  PaginationConfig,
  primaryColor,
  Types,
} from "../../../config";
import { fakeImage } from "../../../config/fakeData";
import UserModal from "./user-config";

const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};

export default function UserList(props) {
  const { setData, userData } = props;

  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const getUserList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `user${ConvertParams(data)}`,
      "get",
      null,
      true,
      {}
    );

    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };
  const DeleteUser = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user/${id}`,
      "delete",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setLoading(true);
      getUserList({
        page,
        pageSize,
        search,
      });
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getUserList({
      page,
      pageSize,
      search,
    });

    return () => {};
  }, []);

  const SwitchAccount = async (id) => {
    const response = await MakeApiCall(
      `user/switch-user/${id}`,
      "GET",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status === true) {
      const type = Types?.[response?.data?.user_data?.user_type];
      const obj = {
        admin: userData,
        user: response?.data,
        loginType: type,
        token: response?.data?.auth_token,
      };

      setData(obj);
      navigate(findRoutes?.[type]);
    } else {
      message.warning(response?.message);
    }
  };

  const [visible, setVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
  };

  const CreateUser = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `sign-up`,
      "post",
      {
        ...data,
        user_type: 2,
      },
      true,
      {},
      userData?.token
    );

    if (response?.status === true) {
      handleCancel();
      setLoading(true);
      getUserList({
        page,
        pageSize,
        search,
      });
      message.destroy();
      message.success(response?.message || "Successfully created");
    } else {
      message.destroy();
      message.warning(response?.message || "Failed to create");
    }
  };
  const UpdateUser = async (data) => {
    if (!editingUser?.id) return;
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user/${editingUser?.id}`,
      "put",
      data,
      true,
      {},
      userData?.token
    );

    if (response?.status === true) {
      handleCancel();
      setLoading(true);
      getUserList({
        page,
        pageSize,
        search,
      });
      message.destroy();
      message.success(response?.message || "Successfully updated");
    } else {
      message.destroy();
      message.warning(response?.message || "Failed to update");
    }
  };

  const handleSubmit = (values) => {
    if (editingUser) {
      console.log("Updated values:", values, editingUser);
      // Handle update logic here
      UpdateUser(values);
    } else {
      CreateUser(values);
      console.log("Submitted values:", values);
      // Handle create logic here
    }
    setVisible(false);
    setEditingUser(null);
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setVisible(true);
  };

  // Columns configuration
  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "pic",
      key: "pic",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (pic, record) => (
        <div>
          <Image
            style={{ borderRadius: "100%" }}
            width={50}
            src={pic || fakeImage}
          />
          <b className="ms-3">{record.name}</b>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Mobile",
      dataIndex: "contact_no",
      key: "contact_no",
      sorter: (a, b) => a.contact_no.localeCompare(b.contact_no),
      render: (e) => {
        return <>{e || "-"}</>;
      },
    },
    {
      title: "Switch User",
      key: "switchUser",
      align: "center",

      render: (_, row) => (
        <Icon
          onClick={() => SwitchAccount(row?.id)}
          icon="clarity:switch-line"
          width={30}
          style={{ color: primaryColor, cursor: "pointer" }}
        />
      ),
    },
    {
      title: "Pending Reports",
      dataIndex: "pending_reports",
      key: "pending_reports",
      sorter: (a, b) => a.pending_reports || 0 - b.pending_reports || 0,
      render: (e) => {
        return <>{AddCommas(e || 0)}</>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => (a.created_at || 0) - (b.created_at || 0),
      render: (e) => {
        return (
          <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at || 0 - b.updated_at || 0,
      render: (e) => {
        return (
          <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, row) => (
        <div>
          <Button
            onClick={() => {
              handleEdit(row);
            }}
            type="link"
          >
            <EditIcon />
          </Button>
          <Popconfirm
            placement="left"
            title="Are you sure you want to delete?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              DeleteUser(row?.id);
            }}
          >
            <Button type="link">
              <DeleteIcon />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="card  pulse">
      <div className="card-header py-2 px-3">
        <div className="card-title">
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onPressEnter={() => {
              getUserList({
                page,
                pageSize,
                search,
              });
            }}
            placeholder="Search by name"
            prefix={<Icon icon="clarity:search-line" />}
          />
        </div>
        <div className="card-toolbar">
          <AddBTN onClick={() => setVisible(true)} type="primary">
            + Add User
          </AddBTN>
        </div>
      </div>
      <div className="card-body px-3">
        <Table2
          scroll={{ x: "max-content" }}
          columns={columns}
          loading={loading}
          dataSource={list}
          pagination={PaginationConfig({
            pageSize,
            page,
            totalPage,
            onChange: (e, f) => {
              setPageSize(f);
              setPage(e);
              getUserList({
                page: e,
                pageSize: f,
                search,
              });
            },
          })}
        />
      </div>
      <UserModal
        visible={visible}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        getList={() => {
          setLoading(true);
          getUserList({
            page,
            pageSize,
            search,
          });
        }}
        initialValues={editingUser}
      />
    </div>
  );
}

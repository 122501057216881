import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  message,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  DefaultPerPage,
  DeleteIcon,
  PlusIcon,
} from "../../../../../../../../../../config";
import Table2 from "../../../../../../../../../../components/tables/table2";
import { MakeApiCall } from "../../../../../../../../../../apis";
const { Text } = Typography;
const CategoryTargeting = (props) => {
  const { marketplaceId } = props;
  const [categoyLoading, setCategoryLoading] = useState(true);
  const [suggCategoryList, setSuggCategoryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);

  useEffect(() => {
    const data = {
      marketplace_id: marketplaceId,
      asins: ["B099643F4N", "B0995Z5CFQ"],
    };
    GetCategoryTargeting(data);
  }, []);

  const GetCategoryTargeting = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-categories-targeting-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setCategoryLoading(false);
      setSuggCategoryList(response?.data);
    } else {
      setCategoryLoading(false);
      setSuggCategoryList([]);
      message.destroy();
      message.error(response?.message);
    }
  };

  const columns = [
    {
      title: "Suggestion",
      dataIndex: "category_name",
      key: "category_name",
      width: 300,
    },
    {
      title: "Sugg. Bid",
      dataIndex: "suggested_bid",
      key: "suggested_bid",
    },
    {
      title: "Action",
      render: (record) => {
        const isAddCategory = selectedCategoryList?.filter(
          (f) => f?.category_id === record?.category_id
        );
        return isAddCategory?.length == 0 ? (
          <span
            onClick={() => {
              const newObj = {
                category_id: record?.category_id,
                suggested_bid: record?.suggested_bid,
                // new_suggested_bid:
                //   bid_selection === "suggested_bid"
                //     ? record?.suggested_bid
                //     : common_default_bid,
                new_suggested_bid: 10,
                category_name: record?.category_name,
              };

              setSelectedCategoryList((prev) => {
                return [...prev, newObj];
              });
            }}
          >
            <PlusIcon />
          </span>
        ) : (
          ""
        );
      },
    },
  ];

  const selectedCatColumns = [
    {
      title: "Suggestion",
      dataIndex: "category_name",
      key: "category_name",
      width: 300,
    },
    {
      title: "Sugg. Bid",
      align: "left",
      render: (record) => {
        return (
          <Flex vertical={true} justify="center">
            <Text strong>{record?.suggested_bid}</Text>
            <Input value={record?.new_suggested_bid} className="w-100px" />
          </Flex>
        );
      },
    },
    {
      title: "Action",
      key: "addAll",
      render: (_, record) => {
        return (
          <span
            onClick={() => {
              const afterDeletingCat = selectedCategoryList?.filter(
                (cl) => cl?.category_id !== record?.category_id
              );
              setSelectedCategoryList(afterDeletingCat);
            }}
          >
            <DeleteIcon />
          </span>
        );
      },
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              All Category
            </Title>
          }
        >
          <Row className="mb-4">
            <Col span={24}>
              <div className="d-flex justify-content-start align-items-center">
                <Text strong>Bid</Text>
                <Select
                  style={{ width: "150px" }}
                  options={[
                    {
                      label: "Suggested Bid",
                      value: "suggested_bid",
                    },
                    {
                      label: "Custom Bid",
                      value: "custom_bid",
                    },
                    {
                      label: "Default Bid",
                      value: "default_bid",
                    },
                  ]}
                  className="mx-3"
                />
                <Input style={{ width: "100px" }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table2
                dataSource={suggCategoryList}
                columns={columns}
                pagination={false}
                loading={categoyLoading}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col sm={24} lg={12} className="">
        <Card
          style={{ minHeight: "400px" }}
          title={
            <Title level={5} className="mb-0">
              Selected Category
            </Title>
          }
          extra={
            <Button type="primary" danger>
              Remove All
            </Button>
          }
        >
          {" "}
          <Row>
            <Col span={24}>
              <Table2
                dataSource={selectedCategoryList}
                columns={selectedCatColumns}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CategoryTargeting;

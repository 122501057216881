import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { Icon } from "@iconify/react/dist/iconify.js";
import { MakeApiCall } from "../../../../../apis";
import NoDataTable from "../../../../../components/no-data-table";
import { TableLoading } from "../../../../../components/table-animation";
import Table2 from "../../../../../components/tables/table2";
import {
  ConvertParams,
  DisplayNotification,
  EditIcon,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  ORIGIN,
  PaginationConfig,
} from "../../../../../config";
import CampaignModal from "./lib/campaign-modal";
import DateWiseActionlogModal from "./lib/date-wise-actionlog-modal";
import ImportFIle from "../../../../../components/import-file";

const RuleBookView = () => {
  const [allCampaignList, setAllCampaignList] = useState([]);
  const [allCampaignListLoading, setAllCampaignListLoading] = useState(true);
  const [dropdownList, setDropdownList] = useState({});
  const [dropdownLoading, setDropdownLoading] = useState(true);
  const [selectedCamp, setSelectedCamp] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [selectedActionLog, setSelectedActionLog] = useState({});
  const [actionLogView, setActionLogView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [ruleList, setRuleList] = useState([]);
  const [updatingRuleStatus, setUpdatingRuleStatus] = useState(false);
  const [isOpenRuleBookModal, setIsOpenRuleBookModal] = useState(false);
  const [rule_details, set_rule_details] = useState({});
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": 20,
    name: "",
    marketplace_id: null,
  });

  useEffect(() => {
    GetMarketplace();
    GetAllDropdownValue();
  }, []);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetCampaignList(marketplace?.marketplace_list[0]?.marketplace_id);
      GetRuleManagementList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
    } else {
      setLoading(false);
    }
  };

  const GetRuleManagementList = async (filters) => {
    const response = await MakeApiCall(
      `rule-management/get-rules${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setRuleList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAllDropdownValue = async () => {
    setLoading(true);
    const response = await MakeApiCall(
      `rule-management/get-create-rule-dd-values`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setDropdownList(response?.data);
      setDropdownLoading(false);
    } else {
      // setDropdownList({});
      setDropdownList({});
      setDropdownLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetCampaignList = async (marketplace_id) => {
    setLoading(true);
    const response = await MakeApiCall(
      `advertising/get-all-campaigns?campaign_state=ENABLED&marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setAllCampaignList(response?.data || []);
      setAllCampaignListLoading(false);
    } else {
      setAllCampaignList([]);
      setAllCampaignListLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const DeleteRuleAction = async (ruleId) => {
    const response = await MakeApiCall(
      `rule-management/master-rule/${ruleId}`,
      `DELETE`,
      null,
      true
    );
    if (response?.status) {
      setLoading(true);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(true);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const UpdateRuleStatusAction = async (ruleId, data) => {
    const response = await MakeApiCall(
      `rule-management/update-status-rule/${ruleId}`,
      `put`,
      data,
      true
    );
    if (response?.status) {
      setLoading(true);
      setUpdatingRuleStatus(false);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(true);
      setUpdatingRuleStatus(false);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetRuleDetailsData = async (id) => {
    const response = await MakeApiCall(
      `rule-management/get-rule/${id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      set_rule_details(response?.data);
      setIsOpenRuleBookModal(true);
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const columns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (_, __, i) => {
        return (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + i}
          </span>
        );
      },
    },

    {
      title: "Rule Name",
      width: 250,
      render: (text) => {
        return <Typography.Text strong>{text?.name || "-"}</Typography.Text>;
      },
    },

    {
      title: "Campaigns",
      width: 300,
      render: (text) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="two-line">
              {JSON.parse(text?.campaign_list || [])?.[0]?.campaign_name || "-"}
            </div>

            <span
              className="ms-2 cursor-pointer"
              onClick={() => {
                setSelectedCamp(JSON.parse(text?.campaign_list || []));
                setCampaignView(true);
              }}
            >
              <Icon icon="solar:eye-line-duotone" width={18} />
            </span>
          </div>
        );
      },
    },

    {
      title: "Rule Status",
      width: 120,
      render: (text) => {
        return (
          <Switch
            size="small"
            disabled={updatingRuleStatus}
            checked={text?.rule_status === "ACTIVE" ? true : false}
            onChange={(e) => {
              console.log(e);
              setUpdatingRuleStatus(true);
              UpdateRuleStatusAction(text?.id, {
                rule_status:
                  text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
              });
              setRuleList((prev) => {
                return prev.map((item) => {
                  if (item?.id === text?.id) {
                    return {
                      ...item,
                      rule_status:
                        text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                    };
                  }
                  return item;
                });
              });
            }}
          />
        );
      },
    },

    {
      title: "Action Log",
      width: 150,
      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedActionLog({
              ...text,
              type_action: "master",
            });
            setActionLogView(true);
          }}
        >
          <Icon icon="material-symbols:history" width={20} />
        </span>
      ),
    },
    {
      title: "Created at",
      width: 150,
      render: (text) => {
        if (!text?.created_at) {
          return "-";
        }

        return (
          <span>
            {dayjs(parseInt(text?.created_at) * 1000).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Updated at",
      width: 150,
      render: (text) => {
        if (!text?.updated_at) {
          return "-";
        }

        return (
          <span>
            {dayjs(parseInt(text?.updated_at) * 1000).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Action",
      width: 150,
      render: (text) => (
        <>
          <span
            className="btn btn-icon bg-light btn-active-color-success btn-sm me-2"
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              GetRuleDetailsData(text?.id);
            }}
          >
            <EditIcon />
          </span>{" "}
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <div className="d-flex flex-wrap gap-4 align-items-center mb-3 justify-content-end">
              <div className="position-relative">
                <Input
                  style={{ width: "250px" }}
                  prefix={
                    <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                  }
                  placeholder="Search by Rule name"
                  size="large"
                  value={all_filters?.name}
                  onChange={(e) => {
                    set_all_filters({
                      ...all_filters,
                      name: e.target.value,
                    });
                  }}
                  onPressEnter={(e) => {
                    const apiObj = {
                      ...all_filters,
                      page: 1,
                      "per-page": 20,
                    };
                    setLoading(true);
                    GetRuleManagementList(apiObj);
                  }}
                />
              </div>

              <div className="position-relative ">
                <Select
                  className="w-200px"
                  size="large"
                  placeholder="Select Marketplace"
                  options={GetMarketplaceList(marketplace_list)}
                  value={selected_marketplace}
                  onChange={(e) => {
                    set_selected_marketplace(e);
                    set_selected_currency_sign(currency_list[e]);
                    const apiObj = {
                      ...all_filters,
                      marketplace_id: e,
                    };
                    set_all_filters(apiObj);

                    const apiObj_ = {
                      ...apiObj,
                      page: 1,
                      "per-page": 20,
                    };
                    setLoading(true);
                    GetRuleManagementList(apiObj_);
                  }}
                />
              </div>
              <Button
                variant="solid"
                color="primary"
                size="large"
                onClick={() => {
                  setIsOpenRuleBookModal(true);
                }}
              >
                <Icon icon="ic:baseline-plus" />
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <div className="card card-shadow  mb-5 mb-xl-8">
              <div className="card-body py-2 px-4 main-box">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : ruleList?.length > 0 ? (
                  <>
                    <Table2
                      columns={columns}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 320px)",
                      }}
                      fixed={true}
                      dataSource={ruleList}
                      rowKey="key"
                      loading={loading}
                      pagination={PaginationConfig({
                        pageSize: all_filters?.["per-page"],
                        page: all_filters?.page,
                        totalPage,
                        onChange: (e, f) => {
                          set_all_filters({
                            ...all_filters,
                            page: e,
                            "per-page": f,
                          });
                          setLoading(true);
                          const apiObj = {
                            ...all_filters,
                            page: e,
                            "per-page": f,
                          };
                          GetRuleManagementList(apiObj);
                        },
                      })}
                    />
                  </>
                ) : (
                  <>
                    <NoDataTable columns={columns} type="no-data" />
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>

        {campaignView && (
          <CampaignModal
            selectedCamp={selectedCamp}
            show={campaignView}
            onHide={() => setCampaignView(false)}
          />
        )}

        {actionLogView && (
          <DateWiseActionlogModal
            show={actionLogView}
            selectedActionLog={selectedActionLog}
            onHide={() => {
              setActionLogView(false);
              setSelectedActionLog({});
            }}
          />
        )}

        {/* {isOpenRuleBookModal && (
          <AddRuleBookModal
            show={isOpenRuleBookModal}
            onHide={() => {
              set_rule_details({});
              setIsOpenRuleBookModal(false);
            }}
            campaign_list={allCampaignList}
            dropdownList={dropdownList}
            currencySign={selected_currency_sign}
            selectedMarketplace={selected_marketplace}
            rule_details={rule_details}
            onSuccess={() => {
              setLoading(true);
              set_rule_details({});
              setIsOpenRuleBookModal(false);
              GetRuleManagementList(all_filters);
            }}
          />
        )} */}
      </div>
    </>
  );
};

export default RuleBookView;

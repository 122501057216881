import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { DeleteIcon } from "../../../../../../../../../../config";
import EnterList from "./enter-list";
import SearchItem from "./search-item";
const { Text } = Typography;

const ManualKeywordTargeting = (props) => {
  const { marketplaceId } = props;

  const [selectedKeywordList, setSelectedKeywordList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("suggested");
  const [selectedFilterBy, setSelectedFilterBy] = useState([
    "BROAD",
    "PHRASE",
    "EXACT",
  ]);

  const items = [
    {
      key: "suggested",
      label: "Suggested",
    },
    {
      key: "enter_list",
      label: "Enter List",
    },
    {
      key: "upload",
      label: "Upload",
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} md={24} lg={24} xl={12} className="">
        <Card
          style={{ minHeight: "500px" }}
          title={
            <Title level={5} className="mb-0">
              Keywords
            </Title>
          }
        >
          <Row className="mb-4">
            <Col span={24}>
              <Tabs
                defaultActiveKey="suggested"
                items={items}
                onChange={(key) => {
                  setSelectedTab(key);
                }}
              />
            </Col>
          </Row>
          {selectedTab !== "upload" && (
            <>
              <Row className="mb-4">
                <Col span={24}>
                  <div className="d-flex align-items-center">
                    <Text strong>Bid</Text>
                    <Select
                      className="mx-3"
                      style={{ width: "150px" }}
                      options={[
                        {
                          label: "Suggested Bid",
                          value: "suggested_bid",
                        },
                        {
                          label: "Custom Bid",
                          value: "custom_bid",
                        },
                        {
                          label: "Default Bid",
                          value: "default_bid",
                        },
                      ]}
                    />
                    <Input style={{ width: "100px" }} />
                  </div>
                </Col>
                <Col span={24} className="mt-4">
                  <div className="d-flex align-items-center">
                    <Text strong className="me-3">
                      Filter by
                    </Text>
                    <Checkbox.Group
                      options={[
                        {
                          label: "Broad",
                          value: "BROAD",
                        },
                        {
                          label: "Phrase",
                          value: "PHRASE",
                        },
                        {
                          label: "Exact",
                          value: "EXACT",
                        },
                      ]}
                      value={selectedFilterBy}
                      onChange={(e) => {
                        if (e?.length > 0) {
                          setSelectedFilterBy(e);
                        } else {
                          message.destroy();
                          message.error("Please select at least one filter");
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {selectedTab === "suggested" && (
            <SearchItem
              selectedKeywordList={selectedKeywordList}
              setSelectedKeywordList={setSelectedKeywordList}
              {...props}
            />
          )}
          {selectedTab === "enter_list" && <EnterList />}
        </Card>
      </Col>
      <Col sm={24} md={24} lg={24} xl={12} className="">
        <Card
          style={{ minHeight: "500px" }}
          title={
            <Title level={5} className="mb-0">
              Selected Keywords
            </Title>
          }
          extra={
            <>
              <Button type="primary" danger>
                Remove All
              </Button>
            </>
          }
        >
          <Row>
            <Col span={24} className="mt-5">
              <Row>
                <Col span={24}>
                  <div className="d-flex">
                    <Text type="secondary" className="me-1">
                      Keyword:
                    </Text>
                    <Text strong>bike led light</Text>
                  </div>
                </Col>
              </Row>
              <Divider className="my-1" />
              <Row>
                <Col span={9}>
                  <Text type="secondary">Match Type:</Text>
                  <br />
                  <Text type="secondary">Sugg. Bid:</Text>
                </Col>
                <Col span={5}>
                  <Text strong>Broad</Text> <br />
                  <div className="d-flex align-items-center">
                    <Text strong>1.95</Text>
                    <DeleteIcon width="17" />
                  </div>
                </Col>
                <Col span={5}>
                  <Text strong>Phrase</Text> <br />
                  <div className="d-flex align-items-center">
                    <Text strong>1.95</Text>
                    <DeleteIcon width="17" />
                  </div>
                </Col>
                <Col span={5}>
                  <Text strong>Exact</Text> <br />
                  <div className="d-flex align-items-center">
                    <Text strong>1.95</Text>
                    <DeleteIcon width="17" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ManualKeywordTargeting;

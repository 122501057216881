import { Col, Row, Segmented, Typography } from "antd";
import React, { useState } from "react";
import { primaryColor } from "../../../../../config";
import { Wrapper } from "./style";
import SponsoredProduct from "./campaign-types/sponsored-product";
const { Text } = Typography;

const AdvertisementCampaignCreation = () => {
  const [selectedType, setSelectedType] = useState("sponsored_product");
  return (
    <>
      <Wrapper>
        <Row>
          <Col span={24} className="mt-0">
            <Segmented
              block
              size="large"
              onChange={(value) => {
                setSelectedType(value);
              }}
              options={[
                {
                  label: (
                    <div
                      className="p-1 d-flex  align-items-center "
                      style={{ flexDirection: "column" }}
                    >
                      <div>
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            background: primaryColor,
                            color: "#fff",
                            fontSize: "13px",
                            borderRadius: "5px",
                          }}
                          className="d-flex align-items-center justify-content-center "
                        >
                          SP
                        </div>
                      </div>

                      <Text strong className="ms-1">
                        Sponsored Product
                      </Text>
                    </div>
                  ),
                  value: "sponsored_product",
                },
                {
                  label: (
                    <div
                      className="p-1 d-flex  align-items-center "
                      style={{ flexDirection: "column" }}
                    >
                      <div>
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            background: primaryColor,
                            color: "#fff",
                            fontSize: "13px",
                            borderRadius: "5px",
                          }}
                          className="d-flex align-items-center justify-content-center "
                        >
                          SB
                        </div>
                      </div>

                      <Text strong className="ms-1">
                        Sponsored Brand
                      </Text>
                    </div>
                  ),
                  value: "sponsored_brand",
                },
                {
                  label: (
                    <div
                      className="p-1 d-flex  align-items-center "
                      style={{ flexDirection: "column" }}
                    >
                      <div>
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            background: primaryColor,
                            color: "#fff",
                            fontSize: "13px",
                            borderRadius: "5px",
                          }}
                          className="d-flex align-items-center justify-content-center "
                        >
                          SD
                        </div>
                      </div>

                      <Text strong className="ms-1">
                        Sponsored Display
                      </Text>
                    </div>
                  ),
                  value: "sponsored_display",
                },
                {
                  label: (
                    <div
                      className="p-1 d-flex  align-items-center "
                      style={{ flexDirection: "column" }}
                    >
                      <div>
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            background: primaryColor,
                            color: "#fff",
                            fontSize: "13px",
                            borderRadius: "5px",
                          }}
                          className="d-flex align-items-center justify-content-center "
                        >
                          ST
                        </div>
                      </div>

                      <Text strong className="ms-1">
                        Sponsored TV
                      </Text>
                    </div>
                  ),
                  value: "sponsored_tv",
                },
              ]}
            />
          </Col>
        </Row>
        {selectedType === "sponsored_product" && <SponsoredProduct />}
        {selectedType === "sponsored_brand" && <label>Sponsored Product</label>}
        {selectedType === "sponsored_display" && (
          <label>Sponsored Product</label>
        )}
        {selectedType === "sponsored_tv" && <label>Sponsored Product</label>}
      </Wrapper>
    </>
  );
};

export default AdvertisementCampaignCreation;

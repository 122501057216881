import Sider from "antd/es/layout/Sider";
import styled from "styled-components";
import { secondaryColor, thirdColor } from "../../../config";

export const Wrapper = styled(Sider)`
  margin-right: 0px;
  margin: 15px 0px 15px 15px;
  border-radius: 12px;
  background: #fff !important;

  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  .sidebar-view {
    overflow: auto;
    min-height: 300px;
    height: calc(100vh - 240px);
    padding: 0px 12px;
  }

  .ant-menu-light {
    .ant-menu-item {
      .ant-menu-item-icon,
      .ant-menu-title-content > a {
        font-size: 14px;
        color: #0f1926 !important;
      }
    }
  }
  .ant-menu-title-content {
    font-size: 14px;
  }
  .ant-menu-light {
    background: transparent;
    border-inline-end: none !important;
    .ant-menu-item-selected {
      background-color: #0f1926;
      box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
      -webkit-animation-name: pulse;
      animation-name: pulse;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      .ant-menu-title-content > a,
      svg {
        color: #fff !important;
      }
    }
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item-selected {
      line-height: 48px !important;
      padding-inline: calc(50% - 10px - 4px) !important;
      box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
      -webkit-animation-name: pulse;
      animation-name: pulse;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background: #0f1926;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
    .ant-menu-item-icon,
    .ant-menu-title-content,
    .ant-menu-submenu-arrow {
      color: #fff !important;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-item-selected {
      background: #0f1926;
      -webkit-animation-name: pulse;
      animation-name: pulse;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
      .ant-menu-item-icon,
      .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #fff !important;
      }
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes pulse {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
`;

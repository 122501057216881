import React, { useRef } from "react";
import { Popover, DatePicker } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";

const PopoverDateView = () => {
  return (
    <Popover
      placement="bottomRight"
      content={<DatePicker.RangePicker needConfirm />}
      trigger="click"
      destroyTooltipOnHide // Ensures cleanup after closing
    >
      <div className="position-relative">
        <Icon width={24} icon="line-md:calendar" />
      </div>
    </Popover>
  );
};

export default PopoverDateView;

import { Button, Input, message, Pagination, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
// import Pagination from "../../../../component/pagination";
import { MakeApiCall } from "../../../apis";
import EmailFormModal from "./add";
import { Icon } from "@iconify/react/dist/iconify.js";
import { AddBTN } from "../../../components/button";
import { PaginationConfig } from "../../../config";
import Table2 from "../../../components/tables/table2";

const EmailDefination = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingData, setEditingData] = useState(null);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchKey, setSearchKey] = useState("");

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const getList = async (data) => {
    const response = await MakeApiCall(
      `email-template?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&search_key=${data?.searchKey || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {};
  }, []);

  // Open modal for adding new data
  const handleAddClick = () => {
    setEditingData(null);
    setModalVisible(true);
  };

  // Open modal for editing existing data
  const handleEditClick = (data) => {
    setEditingData(data);
    setModalVisible(true);
  };

  const addRow = async (data) => {
    const response = await MakeApiCall(`email-template`, "post", data, true);

    if (response?.status) {
      setPage(1);
      getList({ page: 1 });
      setEditingData(null);
      setModalVisible(false);
    } else {
      message.warning(response?.message);
    }
  };
  const editRow = async (data) => {
    const response = await MakeApiCall(
      `email-template/${editingData?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      setPage(1);
      getList({ page: 1 });
      setEditingData(null);
      setModalVisible(false);
    } else {
      message.warning(response?.message);
    }
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    if (editingData) {
      editRow(data);
    } else {
      addRow(data);
    }
  };

  const columns = [
    {
      title: "Sender Email Address",
      dataIndex: "sender_email_address",
      filterIndex: "sender_email_address",
      render: (email) => <strong>{email}</strong>,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      filterIndex: "slug",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      filterIndex: "subject",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      filterIndex: "tags",
      render: (e) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 260,
            }}
          >
            {e}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, row) => (
        <div>
          <Button
            onClick={() => {
              handleEditClick(row);
            }}
            type="link"
          >
            <Icon icon="basil:edit-outline" width={24} />
          </Button>

          <Button
            type="link"
            onClick={() => handleEditClick({ ...row, type: "view" })}
          >
            <Icon
              icon="solar:eye-broken"
              style={{ color: "#3e3e3e" }}
              width={24}
            />
          </Button>
        </div>
      ),
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearchKey("");
    getList({ searchKey: "" });
  };

  return (
    <>
      <div className="card  mb-5 mb-xl-8">
        <div className="card-header  py-2 px-3">
          <div className="card-title">
            <Input
              onChange={(e) => {
                setSearchKey((prev) => {
                  if (!e.target.value && prev) {
                    ClearSearch();
                  }
                  return e.target.value;
                });
              }}
              onPressEnter={() => {
                setLoading(true);
                getList({ searchKey: searchKey });
              }}
              value={searchKey}
              placeholder="Search by name"
              prefix={<Icon icon="clarity:search-line" />}
            />
          </div>
          <div className="card-toolbar">
            <AddBTN onClick={handleAddClick} type="primary">
              + Add
            </AddBTN>
          </div>
        </div>

        <div className="card-body  px-3">
          <Table2
            columns={columns}
            pagination={PaginationConfig({
              pageSize,
              page,
              totalPage,
              onChange: (e, f) => {
                setLoading(true);

                setPageSize(f);
                setPage(e);
                getList({
                  page: e,
                  pageSize: f,
                  searchKey: searchKey,
                });
              },
            })}
            dataSource={list}
            loading={loading}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
      {isModalVisible && (
        <EmailFormModal
          visible={isModalVisible}
          onClose={() => {
            setModalVisible(false);
            setEditingData(null);
          }}
          onSubmit={handleFormSubmit}
          initialValues={editingData}
        />
      )}
    </>
  );
};

export default EmailDefination;

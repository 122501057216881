import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { DateFormate } from "../../../../../../../../config";

const CreateCampaign = (props) => {
  const { campaignData, setCampaignData, setSubTargetingType, setCurrentStep } =
    props;
  console.log(campaignData, "campaignData");
  return (
    <>
      <Row>
        <Col span={24} className="">
          <Form
            layout={"vertical"}
            name="basic"
            initialValues={{
              targeting_type: "AUTO",
              targeting_type_manual: "keyword_targeting",
            }}
            onFinish={(e) => {
              console.log(e, "-e");
              setCampaignData({ ...campaignData, ...e });
              setSubTargetingType(e.targeting_type_manual);
              setCurrentStep(1);
            }}
            //   onFinishFailed={onFinishFailed}
            autoComplete="new-value"
          >
            <Card
              title={
                <Title level={5} className="mb-0">
                  Create Campaign
                </Title>
              }
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    label="Campaign Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Portfolio"
                    name="portfolio_id"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Select options={[{ label: "text", value: "test" }]} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Select Targeting Tactic"
                    name="targeting_type"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="AUTO"> Automatic </Radio>
                      <Radio value="MANUAL"> Manual </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.targeting_type !== currentValues.targeting_type
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("targeting_type") === "MANUAL" && (
                        <Form.Item
                          label="Manual Targeting"
                          name="targeting_type_manual"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select a manual targeting option!",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="keyword_targeting">
                              Keyword Targeting
                            </Radio>
                            <Radio value="product_targeting">
                              Product Targeting
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              className="mt-5"
              title={
                <Title level={5} className="mb-0">
                  Scheduling & Budget
                </Title>
              }
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Start Date"
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <DatePicker format={DateFormate} className="w-100" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="End Date" name="end_date">
                    <DatePicker format={DateFormate} className="w-100" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Daily Budget"
                    name="daily_budget"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={null}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateCampaign;

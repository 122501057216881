import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Col, message, Row } from "antd";
import SponsoredProductSteps from "./lib/steps";
import CreateCampaign from "./lib/create-campaign";
import dayjs from "dayjs";
import { ConvertParams, DateFormate } from "../../../../../../../config";
import AddItems from "./lib/add-items/add-items";
import AddAdgroup from "./lib/add-adgroup";
import NegativeTargeting from "./lib/negative-targeting/negative-targeting";
import ManualTargeting from "./lib/manual-targeting/manual-targeting";
import { MakeApiCall } from "../../../../../../../apis";

const SponsoredProduct = () => {
  const marketplaceId = "A21TJRUUN4KGV";
  const [currentStep, setCurrentStep] = useState(0);
  const [subTargetingType, setSubTargetingType] = useState("");

  const [campaign_data_list, set_campaign_data_list] = useState({
    campaign_ad_type: "SP",
    name: "",
    targeting_type: "AUTO",
    start_date: dayjs()?.format(DateFormate),
    end_date: null,
    daily_budget: "",
    portfolio_id: null,
    bidding_strategy: "LEGACY_FOR_SALES",
    top_of_search_placement: 0,
    product_pages_placement: 0,
    rest_of_search_placement: 0,
    state: "ENABLED",
  });

  return (
    <Wrapper className="mt-5">
      <Row gutter={16}>
        <Col xs={10} md={9} lg={5} className="mt-0">
          <SponsoredProductSteps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </Col>
        <Col xs={14} md={15} lg={19}>
          {currentStep === 0 && (
            <CreateCampaign
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
              setSubTargetingType={setSubTargetingType}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 1 && (
            <AddItems
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
            />
          )}{" "}
          {currentStep === 2 && (
            <AddAdgroup
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
            />
          )}{" "}
          {currentStep === 3 && (
            <NegativeTargeting
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
              marketplaceId={marketplaceId}
            />
          )}{" "}
          {currentStep === 4 && (
            <ManualTargeting
              campaignData={campaign_data_list}
              setCampaignData={set_campaign_data_list}
              marketplaceId={marketplaceId}
            />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SponsoredProduct;

export const UserMenus = [
  // Dashboard
  {
    label: "Dashboard",
    key: "dashboard",
    icon: "material-symbols:dashboard",
  },
  // Product
  {
    label: "Product Details",
    key: "product-details",
    icon: "ix:product",
  },
  // Analytics
  {
    label: "Analytics",
    key: "analytics",
    icon: "tabler:dashboard-filled",
    children: [
      {
        key: "analytics-dashboard",
        label: "Dashboard",
      },
      {
        key: "pl-statement",
        label: "P & L statement",
      },
      {
        key: "product-analytics",
        label: "Product Analytics",
      },
    ],
  },
  // Advertisement
  {
    label: "Advertisement",
    key: "advertisement",
    icon: "ri:advertisement-fill",
    children: [
      {
        key: "advertisement-dashboard",
        label: "Dashboard",
      },
      {
        key: "advertisement-product-analytics",
        label: "Product Analytics",
      },
      {
        key: "campaign-management",
        label: "Campaign Management",
      },
      {
        key: "rule-management",
        label: "Rule Management",
        children: [
          {
            key: "rule-book",
            label: "Rule Book",
          },
          {
            key: "day-parting",
            label: "Day Parting",
          },
        ],
      },
    ],
  },
  // Listing Optimizer
  {
    label: "Listing Optimizer",
    key: "listing-optimizer",
    icon: "lucide:chart-bar",
  },
  // Reimbursement
  {
    label: "Reimbursement",
    key: "reimbursement",
    icon: "ri:currency-fill",
    children: [
      {
        key: "reimbursement-dashboard",
        label: "Dashboard",
      },
      {
        key: "reimbursement-details",
        label: "Details",
      },
      {
        key: "reimbursement-manager",
        label: "Manager",
      },
    ],
  },
  // Product Research
  {
    label: "Product Research",
    key: "product-research",
    icon: "game-icons:archive-research",
  },
  // Inventory Management
  {
    label: "Inventory Management",
    key: "inventory-management",
    icon: "mingcute:inventory-fill",
  },
  // Extra Tools
  {
    label: "Extra Tools",
    key: "extra-tools",
    icon: "clarity:tools-solid",
    children: [
      {
        key: "recommendation-alerts",
        label: "Recommendation Alerts",
      },
      {
        key: "feedback-automation",
        label: "Feedback Automation",
      },
      {
        key: "account-health",
        label: "Account Health",
      },
      {
        key: "profit-analysis",
        label: "Profit Analysis",
      },
    ],
  },
  // Report Center
  {
    label: "Report Center",
    key: "report-center",
    icon: "bxs:report",
    children: [
      {
        key: "advertisement-report",
        label: "Advertisement Report",
      },
    ],
  },
  // Setting
  {
    label: "Setting",
    key: "setting",
    icon: "tdesign:setting-filled",
  },
  // Application Logs
  {
    label: "Application Logs",
    key: "application-logs",
    icon: "eos-icons:application",
    children: [
      {
        key: "central-log",
        label: "Central Log",
      },
      {
        key: "system-error-log",
        label: "System Error Log",
      },
    ],
  },
  {
    label: "Data Scheduler",
    key: "data-scheduler",
    icon: "mdi:invoice-schedule",
  },
];

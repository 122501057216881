import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Upload,
  Avatar,
  Row,
  Col,
  message,
} from "antd";
import React, { useEffect, useState } from "react";

import { UserOutlined, InboxOutlined } from "@ant-design/icons";
import { DisplayNotification, ORIGIN } from "../../../config";
import { MakeApiCall } from "../../../apis";

const UserModal = ({ visible, onCancel, getList, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setLoading(false);
      form.resetFields(); // Reset form fields when the modal is opened
      form.setFieldsValue(initialValues || { user_type: 2 }); // Set initial values or default to user_type = 2
    }
  }, [visible, form, initialValues]);

  const isEdit = Object.keys(initialValues || {})?.length !== 0;

  const onSubmitUser = async (values) => {
    let formData = new FormData();
    formData.append("address", values?.address);
    formData.append("contact_no", values?.contact_no);
    formData.append("name", values?.name);
    formData.append("photo", values?.photo?.file || values?.photo);
    if (!isEdit) {
      formData.append("email", values?.email);
      formData.append("password", values?.password);
    }
    const response = await MakeApiCall(
      isEdit ? `user/${initialValues?.id}` : "user",
      "post",
      formData,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    );
    if (response?.status) {
      setLoading(false);
      getList();
      DisplayNotification("success", "Success", response?.message);
      onCancel();
    } else {
      DisplayNotification("error", "Error", response?.message);
      setLoading(false);
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        onSubmitUser(values);
        // console.log(values, "values");

        // onSubmit(values);
        // setTimeout(() => {
        //   setLoading(false);
        //   form.resetFields();
        //   onCancel();
        // }, 2000); // Simulate API call
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={isEdit ? "Edit User" : "Create a New User"}
      onCancel={onCancel}
      width={800}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          {isEdit ? "Update" : "Submit"}
        </Button>,
      ]}
    >
      <>
        <Form form={form} layout="vertical">
          <Form.Item label="Avatar" name="photo">
            <Upload
              defaultFileList={
                initialValues?.photo
                  ? [
                      {
                        uid: "-1",
                        name: initialValues?.photo?.split("/").pop(),
                        status: "done",
                        url: ORIGIN + initialValues?.photo,
                      },
                    ]
                  : null
              }
              onChange={(e) => {
                if (e?.fileList?.length === 0) {
                  form.setFieldsValue({ photo: null });
                  form.validateFields();
                }
              }}
              listType="picture"
              beforeUpload={() => false}
              maxCount={1}
            >
              <Button icon={<InboxOutlined />}>Choose File</Button>
            </Upload>
          </Form.Item>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input
                  autoComplete="new-password"
                  variant="filled"
                  placeholder="Enter your name"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input
                  disabled={isEdit}
                  autoComplete="new-password"
                  variant="filled"
                  placeholder="Enter your email"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              {" "}
              <Form.Item
                label="Contact No"
                name="contact_no"
                rules={[
                  {
                    required: true,
                    message: "Please enter your contact number",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Contact number must be numeric",
                  },
                  {
                    min: 10,
                    message: "Contact number must be at least 10 digits",
                  },
                ]}
              >
                <Input
                  autoComplete="new-password"
                  variant="filled"
                  placeholder="Enter your contact number"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  { required: true, message: "Please enter your address" },
                ]}
              >
                <Input
                  autoComplete="new-password"
                  variant="filled"
                  placeholder="Enter your address"
                  size="large"
                />
              </Form.Item>
            </Col>
            {!isEdit && (
              <>
                <Col span={8}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      { required: true, message: "Please enter your password" },
                    ]}
                  >
                    <Input.Password
                      autoComplete="new-password"
                      variant="filled"
                      placeholder="Enter your password"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      variant="filled"
                      autoComplete="new-password"
                      placeholder="Confirm your password"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </>
    </Modal>
  );
};

export default UserModal;

import React, { useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  Card,
  Col,
  Collapse,
  Drawer,
  Row,
  Select,
  Switch,
  Avatar,
  Table,
  Typography,
  Popover,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import Title from "antd/es/typography/Title";
import { Icon } from "@iconify/react/dist/iconify.js";
import PopoverDateView from "../../../../components/popover-date";

const { Text } = Typography;
const AnalyticsDashboard = () => {
  const [open, setOpen] = useState(false);

  const [isRowVisible, setIsRowVisible] = useState(true);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleSwitchChange = (checked) => {
    console.log(`switch to ${checked}`);
    setIsRowVisible(checked);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Define columns for the table
  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text) => (
        <img src={text} alt="Product" style={{ width: 50, height: 50 }} />
      ), // Renders image
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
    },
    {
      title: "Est. Profit",
      dataIndex: "estProfit",
      key: "estProfit",
    },
    {
      title: "Units Sold",
      dataIndex: "unitsSold",
      key: "unitsSold",
    },
    {
      title: "PPC Units Sold",
      dataIndex: "ppcUnitsSold",
      key: "ppcUnitsSold",
    },
    {
      title: "Organic Units Sold",
      dataIndex: "organicUnitsSold",
      key: "organicUnitsSold",
    },
    {
      title: "Organic Sales",
      dataIndex: "organicSales",
      key: "organicSales",
    },
    {
      title: "PPC Sales",
      dataIndex: "ppcSales",
      key: "ppcSales",
    },
    {
      title: "Ads Spent",
      dataIndex: "adsSpent",
      key: "adsSpent",
    },
    {
      title: "Amazon Fees",
      dataIndex: "amazonFees",
      key: "amazonFees",
    },
    {
      title: "COGS",
      dataIndex: "cogs",
      key: "cogs",
    },
    {
      title: "General Costs",
      dataIndex: "generalCosts",
      key: "generalCosts",
    },
    {
      title: "Refund Cost",
      dataIndex: "refundCost",
      key: "refundCost",
    },
    {
      title: "Refund Unit",
      dataIndex: "refundUnit",
      key: "refundUnit",
    },
  ];

  const data = [
    {
      key: "1",
      product: "https://m.media-amazon.com/images/I/41X75DlvJnL._SL75_.jpg",
      asin: "B0CFG2K7YY",
      sku: "A1-TRPD-US",
      sales: 4926.02,
      estProfit: 1546.87,
      unitsSold: 55,
      ppcUnitsSold: 26,
      organicUnitsSold: 29,
      organicSales: 2627.68,
      ppcSales: 2298.34,
      adsSpent: -841.37,
      amazonFees: -1418.04,
      cogs: 0.0,
      generalCosts: 0.0,
      refundCost: -830.47,
      refundUnit: 12,
    },
    {
      key: "2",
      product: "https://m.media-amazon.com/images/I/41X75DlvJnL._SL75_.jpg",
      asin: "B0838L98WD",
      sku: "G1-73IV-2QM9",
      sales: 1432.6,
      estProfit: 72.28,
      unitsSold: 42,
      ppcUnitsSold: 42,
      organicUnitsSold: 0,
      organicSales: -1087.9,
      ppcSales: 2520.5,
      adsSpent: -762.11,
      amazonFees: -509.34,
      cogs: 0.0,
      generalCosts: 0.0,
      refundCost: -47.44,
      refundUnit: 1,
    },
  ];

  // Sample data
  const products = [
    {
      id: "1",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      sku: "SKU00001",
      image: "https://via.placeholder.com/40", // replace with actual image URLs
    },
    {
      id: "2",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      sku: "SKU00002",
      image: "https://via.placeholder.com/40",
    },
    {
      id: "3",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      sku: "SKU00003",
      image: "https://via.placeholder.com/40",
    },
  ];

  return (
    <Wrapper>
      <>
        {/*  */}
        <>
          <Row>
            <Col span={24}>
              <Card
                title={<Title level={5}>Overview</Title>}
                extra={
                  <div>
                    Compare{" "}
                    <Switch
                      className="ms-3"
                      defaultChecked
                      onChange={onChange}
                    />
                  </div>
                }
              >
                <Row gutter={16}>
                  <Col span={6} className="">
                    <Card
                      title={<Text>Today</Text>}
                      extra={<PopoverDateView />}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text type="secondary">Sales</Text>
                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Units Sold</Text>

                          <h4>10</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Costs</Text>

                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Est. Profit</Text>
                          <h4>10</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        <span>Breakdown</span>
                        <Icon
                          style={{ position: "relative", top: 1 }}
                          icon="mingcute:arrow-right-fill"
                        />
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title={<Text>Yesterday</Text>}
                      extra={
                        <div className="position-relative">
                          <Icon width={24} icon="solar:calendar-bold-duotone" />
                        </div>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text type="secondary">Sales</Text>
                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Units Sold</Text>

                          <h4>10</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Costs</Text>

                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Est. Profit</Text>
                          <h4>10</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        <span>Breakdown</span>
                        <Icon
                          style={{ position: "relative", top: 1 }}
                          icon="mingcute:arrow-right-fill"
                        />
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title={<Text>Last 7 days</Text>}
                      extra={
                        <div className="position-relative">
                          <Icon width={24} icon="solar:calendar-bold-duotone" />
                        </div>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text type="secondary">Sales</Text>
                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Units Sold</Text>

                          <h4>10</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Costs</Text>

                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Est. Profit</Text>
                          <h4>10</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        <span>Breakdown</span>
                        <Icon
                          style={{ position: "relative", top: 1 }}
                          icon="mingcute:arrow-right-fill"
                        />
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title={<Text>Last 30 days</Text>}
                      extra={
                        <div className="position-relative">
                          <Icon width={24} icon="solar:calendar-bold-duotone" />
                        </div>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Text type="secondary">Sales</Text>
                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Units Sold</Text>

                          <h4>10</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Costs</Text>

                          <h4>$839.00</h4>
                        </Col>
                        <Col span={12}>
                          <Text type="secondary">Est. Profit</Text>
                          <h4>10</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        <span>Breakdown</span>
                        <Icon
                          style={{ position: "relative", top: 1 }}
                          icon="mingcute:arrow-right-fill"
                        />
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>{/* <PerformanceChart /> */}</Col>
            <Col className="mt-3" span={24}>
              <Card
                title="Top products"
                extra={
                  <div className="d-flex flex-row gap-4">
                    <Select
                      showSearch
                      placeholder="Custom KPI"
                      optionFilterProp="label"
                      variant="filled"
                      size="large"
                      onChange={onChange}
                      onSearch={onSearch}
                      defaultValue={["ppc sales", "products", "unit sold"]}
                      className="min-w-200px"
                      mode="multiple"
                      maxTagCount="responsive"
                      options={[
                        {
                          value: "ppc sales",
                          label: "PPC Sales",
                        },
                        {
                          value: "products",
                          label: "Products",
                        },
                        {
                          value: "unit sold",
                          label: "Unit Sold",
                        },
                      ]}
                    />
                    <Select
                      placeholder="Select a product"
                      style={{ width: "100%" }}
                      variant="filled"
                      size="large"
                      optionLabelProp="label"
                      className="min-w-200px"
                      mode="multiple"
                      maxTagCount="responsive"
                    >
                      {products.map((product) => (
                        <Select.Option
                          key={product.id}
                          value={product.id}
                          label={product.name}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              src={product.image}
                              size={40}
                              style={{ marginRight: 8 }}
                            />
                            <div>
                              <Text className="text-one" strong>
                                {product.name}
                              </Text>
                              <Text type="secondary">SKU: {product.sku}</Text>
                            </div>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                    {/* <RangePickerESF variant='filled' className="w-225px"/> */}
                  </div>
                }
              >
                <Table
                  columns={columns}
                  dataSource={data}
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Card>
            </Col>
          </Row>
        </>

        <Drawer width="450px" title="Breakdown" onClose={onClose} open={open}>
          <div className=" breakdown-drawer">
            <Row>
              <Col span={12}>
                <Title level={4}>Start Date: 2024-11-07</Title>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Title level={4}>End Date: 2024-11-07</Title>
              </Col>
            </Row>

            <Collapse defaultActiveKey={[]}>
              <Collapse.Panel
                header="Sales"
                extra={<span className="fw-bolder">$319.60</span>}
                key="1"
              >
                <Row className="sub">
                  <Col span={12}>
                    <Text>Organic</Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Text>$319.60</Text>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                header="Units Sold"
                extra={<span className="fw-bolder">3</span>}
                key="2"
              >
                <Row className="sub">
                  <Col span={12}>
                    <Text>Organic unit</Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Text>3</Text>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                header="FBA Fees"
                extra={<span className="fw-bolder">-$113.53</span>}
                key="3"
              >
                <Row className="sub mb-2">
                  <Col span={16}>
                    <Text>Fba Per Unit Fulfillment Fee</Text>
                  </Col>
                  <Col span={8} style={{ textAlign: "right" }}>
                    <Text>-$65.56</Text>
                  </Col>
                </Row>
                <Row className="sub">
                  <Col span={12}>
                    <Text>Commission</Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Text>-$47.97</Text>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                header="PPC Costs"
                extra={<span className="fw-bolder">-$1.19</span>}
                key="4"
              >
                <Row className="sub mb-0">
                  <Col span={16}>
                    <Text>Sponsored Brands Video PPC</Text>
                  </Col>
                  <Col span={8} style={{ textAlign: "right" }}>
                    <Text>-$65.56</Text>
                  </Col>
                </Row>
              </Collapse.Panel>

              <Collapse.Panel
                showArrow={false}
                collapsible="icon"
                className="main-item mt-3"
                header="Total Profit"
                extra={<span className="fw-bolder">$204.98</span>}
                key="5"
              ></Collapse.Panel>

              <Collapse.Panel
                showArrow={false}
                collapsible="icon"
                className="main-item"
                header="Profit Margin"
                extra={<span className="fw-bolder">64.12%</span>}
                key="6"
              ></Collapse.Panel>
            </Collapse>
          </div>
        </Drawer>
      </>
    </Wrapper>
  );
};

export default AnalyticsDashboard;

import SignIn from "../modules/auth/sign-in";
import SignUp from "../modules/auth/sign-up";
import { UserVerify } from "../modules/auth/sign-up/user-verify";

export const Auth = [
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/user-verify",
    element: <UserVerify />,
  },
];

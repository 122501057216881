import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth"; // Adjust the path as necessary

const ProtectedRoute = ({
  element,
  user,
  setData,
  session,
  setSessionData,
}) => {
  return isAuthenticated(user) ? (
    React.cloneElement(element, {
      userData: user,
      setData: setData,
      setSessionData: setSessionData,
      session: session,
    })
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default ProtectedRoute;

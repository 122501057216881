import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";
import { AdminMenus } from "./admin";
import { UserMenus } from "./user";

const colors = [
  "#3a6dfb",
  "#f08e8f",
  "#00c2f2",
  "#ffa701",
  "#39ca0c",
  "#a3a3a3",
];

export const FindMenus = (type) => {
  return {
    admin: AdminMenus,
    user: UserMenus,
  }?.[type]?.map((d, i) => {
    if (d?.children) {
      return {
        ...d,
        icon: (
          <div>
            <Icon icon={d.icon} width={22} />
          </div>
        ),

        label: d.label,
        children: d?.children?.map((r) => {
          if (r?.children) {
            return {
              ...r,
              icon: <></>,
              label: r.label,
              children: r?.children?.map((rr) => {
                return {
                  ...rr,
                  label: <Link to={rr.key}>{rr.label}</Link>,
                };
              }),
            };
          }
          return {
            ...r,
            icon: <></>,
            label: <Link to={r.key}>{r.label}</Link>,
          };
        }),
      };
    }
    return {
      ...d,
      icon: (
        <div>
          <Icon icon={d.icon} width={22} />
        </div>
      ),
      label: <Link to={d.key}>{d.label}</Link>,
    };
  });
};

import styled from "styled-components";
import { secondaryColor } from "../../../config";

export const BackToAdminWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 120px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  border: none;
  background-size: 200% 200%;
  background-color: #0f1926;

  background-position: left;
  cursor: pointer;
  border-radius: 12px;
  transition: all 3s;

  &:hover {
    box-shadow: 0px 0px 20px #7c7c7c;
    background-position: right;
  }
`;
export const ProfileView = styled.div`
  .footer-sidebar {
    display: flex;
    align-items: center;
    height: 50px;
  }
  .NameFooter {
    font-weight: 700 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px !important;
    color: ${secondaryColor} !important;
  }
  .EmailFooter {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
  }
`;

import AdvertisementDashboard from "../modules/user/advertisement/advertisement-dashboard/index.js";
import AdvertisementCampaignManagement from "../modules/user/advertisement/campaign-management/index.js";
import KeywordTargeting from "../modules/user/advertisement/keyword-management/keyword-targeting";
import AdvertisementProductAnalytics from "../modules/user/advertisement/product-analytics/index.js";
import DayParting from "../modules/user/advertisement/rule-management/day-parting/index.js";
import RuleBook from "../modules/user/advertisement/rule-management/rule-book/index.js";
import AnalyticsDashboard from "../modules/user/analytics/dashboard/index.js";
import PAndLStatement from "../modules/user/analytics/p&l-statement/index.js";
import ProductAnalytics from "../modules/user/analytics/product-analytics/index.js";
import CentralLog from "../modules/user/application-logs/central-log/index.js";
import SystemErrorLog from "../modules/user/application-logs/system-error-log/index.js";
import Dashboard from "../modules/user/dashboard/index.js";
import DataScheduler from "../modules/user/data-scheduler/index.js";
import AccountHealth from "../modules/user/extra-tools/account-health/index.js";
import FeedbackAutomation from "../modules/user/extra-tools/feedback-automation/index.js";
import ProfitAnalysis from "../modules/user/extra-tools/profit-analysis/index.js";
import RecommendationAlerts from "../modules/user/extra-tools/recommendation-alerts/index.js";
import InventoryManagement from "../modules/user/inventory-management/index.js";
import ListingOptimizer from "../modules/user/listing-optimizer/index.js";
import ProductDetails from "../modules/user/product-details/index.js";
import ProductResearch from "../modules/user/product-research/index.js";
import ReimbursementDashboard from "../modules/user/reimbursement/reimbursement-dashboard/index.js";
import ReimbursementDetails from "../modules/user/reimbursement/reimbursement-details/index.js";
import ReimbursementManager from "../modules/user/reimbursement/reimbursement-manager/index.js";
import AdvertisementReport from "../modules/user/report-center/advertisement-report/index.js";
import Setting from "../modules/user/setting/index.js";

export const Users = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "product-details",
    element: <ProductDetails />,
  },
  // Analytics
  {
    path: "analytics-dashboard",
    element: <AnalyticsDashboard />,
  },
  {
    path: "pl-statement",
    element: <PAndLStatement />,
  },
  {
    path: "product-analytics",
    element: <ProductAnalytics />,
  },
  // Advertisement
  {
    path: "advertisement-dashboard",
    element: <AdvertisementDashboard />,
  },
  {
    path: "advertisement-product-analytics",
    element: <AdvertisementProductAnalytics />,
  },
  {
    path: "campaign-management",
    element: <AdvertisementCampaignManagement />,
  },
  {
    path: "rule-book",
    element: <RuleBook />,
  },
  {
    path: "day-parting",
    element: <DayParting />,
  },
  {
    path: "advertisement/keyword-management/keyword-targeting",
    element: <KeywordTargeting />,
  },
  // listing-optimizer
  {
    path: "listing-optimizer",
    element: <ListingOptimizer />,
  },
  // reimbursement
  {
    path: "reimbursement-dashboard",
    element: <ReimbursementDashboard />,
  },
  {
    path: "reimbursement-details",
    element: <ReimbursementDetails />,
  },
  {
    path: "reimbursement-manager",
    element: <ReimbursementManager />,
  },
  // product-research
  {
    path: "product-research",
    element: <ProductResearch />,
  },
  // inventory-management
  {
    path: "inventory-management",
    element: <InventoryManagement />,
  },
  // extra-tools
  {
    path: "recommendation-alerts",
    element: <RecommendationAlerts />,
  },
  {
    path: "feedback-automation",
    element: <FeedbackAutomation />,
  },
  {
    path: "account-health",
    element: <AccountHealth />,
  },
  {
    path: "profit-analysis",
    element: <ProfitAnalysis />,
  },
  // report-center
  {
    path: "advertisement-report",
    element: <AdvertisementReport />,
  },
  // setting
  {
    path: "setting",
    element: <Setting />,
  },
  // application-logs
  {
    path: "central-log",
    element: <CentralLog />,
  },
  {
    path: "system-error-log",
    element: <SystemErrorLog />,
  },
  // data-scheduler
  {
    path: "data-scheduler",
    element: <DataScheduler />,
  },
];

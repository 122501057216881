import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MakeApiCall } from "../../../apis";
import { DefaultAdminPage, DefaultUserPage } from "../../../config";

import Captcha from "./CAPTCHA";

const WrapperSignIn = styled.div`
  height: 100%;
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  .login {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(
        135deg,
        transparent 0%,
        transparent 26%,
        rgba(19, 135, 203, 0.6) 26%,
        rgba(19, 135, 203, 0.6) 72%,
        transparent 72%,
        transparent 82%,
        rgba(6, 156, 244, 0.6) 82%,
        rgba(6, 156, 244, 0.6) 100%
      ),
      linear-gradient(
        45deg,
        transparent 0%,
        transparent 4%,
        rgb(19, 135, 203) 4%,
        rgb(19, 135, 203) 22%,
        rgb(45, 92, 120) 22%,
        rgb(45, 92, 120) 60%,
        transparent 60%,
        transparent 100%
      ),
      linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
    position: relative;
  }

  .login_box {
    max-width: 600px;
    width: 100%;
    background-color: #fff;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #f1f1f4;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 2.5rem;
    .text-gray-500 {
      color: #78829d;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      margin-inline-end: 0.375rem;
      text-align: center;
      margin-bottom: 1.3rem;
    }
  }
  .tracking-in-expand-fwd {
    text-align: center;
  }
`;

const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};
const { Option } = Select;
export default function SignUp() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [emailValidationLoading, setEmailValidationLoading] = useState(false);
  const [couponCodeValidation, setCouponCodeValidation] = useState(false);
  const [reloadCode, setReloadCode] = useState(false);
  const [couponCodeValidationLoading, setCouponCodeValidationLoading] =
    useState(false);

  const navigate = useNavigate();

  const [signUpLoading, setSignUpLoading] = useState(false);
  const [flagListLoading, setFlagListLoading] = useState(true);
  const [flagList, setFlagList] = useState([]);
  const [couponCodeId, setCouponCodeId] = useState(null);

  const [errorView, setErrorView] = useState({
    show: false,
    message: "",
    type: "",
  });

  const next = () => {
    if (current === 0) {
      setCurrent(current + 1);
      setErrorView({
        show: false,
        message: null,
        type: "",
      });
    }
  };
  const prev = () => {
    setCurrent(current - 1);
    if (location?.pathname === "/user-verify") {
      navigate("/user-verify");
    }
    form.setFieldsValue({
      password: "",
      confirmPassword: "",
      first_name: "",
      last_name: "",
      contact_no: "",
      seller_name: "",
      country: "",
      address1: "",
      address2: "",
      city: "",
      captcha: "",
      state: "",
      zip_code: "",
      coupon_code: "",
    });
    setErrorView({
      show: false,
      message: null,
      type: "",
    });
  };
  const getFlagList = async () => {
    setFlagListLoading(true);
    const response = await MakeApiCall("countries", "get", null);

    if (response?.status) {
      setFlagList(response?.data || []);
      setFlagListLoading(false);
    } else {
      message.warning(response?.message);
      setFlagList([]);
      setFlagListLoading(false);
    }
  };

  useEffect(() => {
    getFlagList();
    return () => {};
  }, []);

  const checkEmail = async (values) => {
    setEmailValidationLoading(true);
    const response = await MakeApiCall("auth/seller/step1", "post", {
      ...values,
    });
    if (response?.status) {
      setSignUpLoading(false);
      if (response?.data?.is_email) {
        next();
        if (response?.data?.email_message) {
          setErrorView({
            show: true,
            message: response?.data?.email_message,
            type: "success",
          });
        }
      } else {
        if (response?.message) {
          setErrorView({
            show: true,
            message: response?.message,
            type: "error",
          });
        }
      }
      setEmailValidationLoading(false);
    } else {
      setSignUpLoading(false);
      setErrorView({
        show: true,
        message: response?.message,
        type: "error",
      });
      setEmailValidationLoading(false);
    }
  };
  const checkCouponCode_ = async (values) => {
    return await MakeApiCall("check-coupon-code", "post", {
      ...values,
    });
  };

  const submit = async (values) => {
    const { contact_code, contact_no, ...rest } = values;

    setSignUpLoading(true);
    const response = await MakeApiCall("auth/seller/signup", "post", {
      ...{
        ...rest,
        contact_no: "+" + contact_code + contact_no,
        status: location.search ? 1 : 0,
        email_verify: location.search ? 1 : 0,
        coupon_code_id: couponCodeId || null,
      },
    });
    if (response?.status) {
      setSignUpLoading(false);
      form.resetFields();

      setErrorView({
        show: true,
        message: response?.message,
        type: "success",
      });
      setTimeout(() => {
        window.location.replace("/login");
      }, 2000);
    } else {
      setSignUpLoading(false);
      setErrorView({
        show: true,
        message: response?.message,
        type: "error",
      });
    }
  };

  const filterOption = (input, option) => {
    const inputLower = input?.toLowerCase();
    const countryName = option?.countryName?.toLowerCase();

    return countryName?.includes(inputLower);
  };

  const CheckToken = async (token) => {
    next();
    form.setFieldsValue({
      email: token?.split("email=")?.[1],
    });
  };

  useEffect(() => {
    if (location?.pathname === "/sign-up" && location.search) {
      CheckToken(location.search);
    }
  }, [location?.pathname]);
  const labelStyle = {
    color: "#A8A8A8",
  };
  const selectBefore = (
    <Form.Item name="contact_code" style={{ height: "13px" }}>
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        loading={flagListLoading}
        showSearch
        className=" mobile-number"
        style={{
          position: "relative",
          height: "38px",
          top: "2px",
          // width: "150px",
        }}
        dropdownStyle={{ width: 300 }}
        filterOption={filterOption}
        placeholder={
          <>
            <div
              style={{
                backgroundColor: "#DBDBDB",
                width: "20px",
                height: "15px",
              }}
            ></div>
          </>
        }
      >
        {flagList?.map((d, i) => (
          <Option value={d?.phone_code} key={i} countryName={d?.country_name}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "contain",
                  marginRight: "6px",
                }}
                src={d?.flag_url}
                loading="lazy"
              />
              <span
                className="country_name"
                style={{
                  color: "#777",
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: '"Lato", sans-serif',
                  marginRight: "6px",
                }}
              >
                {d?.country_name}
              </span>
              <span
                style={{
                  color: "#999",
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: '"Lato", sans-serif',
                  marginRight: "6px",
                }}
              >
                +{d?.phone_code}
              </span>
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const steps = [
    {
      title: "",
      content: (
        <>
          <Form.Item
            name="email"
            label={<span style={labelStyle}>Email</span>}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
              {
                required: true,
                message: "Please enter your email address",
              },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>
        </>
      ),
    },
    {
      title: "",
      content: (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Divider
                textPaddingInline={0}
                orientationMargin={0}
                orientation="left"
                plain
                style={{ fontSize: "16px", color: "#23262F", marginTop: 0 }}
              >
                User Info
              </Divider>
            </Col>
            <Col span={24} className="mb-2">
              <Input
                disabled
                size="large"
                value={location.search?.replace("?email=", "")}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                  },
                ]}
                name="first_name"
                autoComplete="new-password"
                label={<span style={labelStyle}>First Name</span>}
              >
                <Input size="large" placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                  },
                ]}
                name="last_name"
                autoComplete="new-password"
                label={<span style={labelStyle}>Last Name</span>}
              >
                <Input size="large" placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span style={labelStyle}>Password</span>}
                name="password"
                autoComplete="new-password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long!",
                  },
                  {
                    pattern: /^\S*$/,
                    message: "Password cannot contain blank spaces!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  autoComplete="new-password"
                  type="password"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label={<span style={labelStyle}>Confirm Password</span>}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirm password is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Password mismatch");
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  style={{ width: "100%" }}
                  autoComplete="new-password"
                  type="password"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="contact_no"
            label={<span style={labelStyle}>Mobile Number</span>}
            rules={[
              { required: true, message: "Please enter your mobile number" },
              {
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const regex = /^\d{1,15}$/;
                  if (!regex.test(value)) {
                    return Promise.reject(
                      "Mobile number should only contain digits"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Mobile Number"
              autoComplete="new-password"
              addonBefore={selectBefore}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Divider
                textPaddingInline={0}
                orientationMargin={0}
                orientation="left"
                plain
                style={{ fontSize: "16px", color: "#23262F" }}
              >
                Seller Info
              </Divider>
            </Col>
          </Row>
          <Form.Item
            rules={[
              { required: true, message: "Please enter your seller name" },
            ]}
            name="seller_name"
            label={<span style={labelStyle}>Seller Name</span>}
          >
            <Input
              size="large"
              autoComplete="new-password"
              placeholder="Seller Name"
            />
          </Form.Item>
          <Form.Item
            name="country"
            label={<span style={labelStyle}>Country</span>}
          >
            <Select
              // getPopupContainer={(triggerNode) => triggerNode.parentNode}
              loading={flagListLoading}
              className=""
              showSearch
              size="large"
              placeholder="Select Country"
            >
              {flagList?.map((d, i) => (
                <Option value={d?.country_name} key={i}>
                  <img
                    style={{
                      width: "25px",
                      height: "25px",
                      objectFit: "contain",
                      marginRight: "6px",
                    }}
                    src={d?.flag_url}
                    loading="lazy"
                  />
                  <span
                    className="country_name"
                    style={{
                      color: "#777",
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: '"Lato", sans-serif',
                      marginRight: "6px",
                    }}
                  >
                    {d?.country_name}
                  </span>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={<span style={labelStyle}>Address</span>}
                name="address"
              >
                <Input size="large" placeholder="Address" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="city"
                label={<span style={labelStyle}>City</span>}
              >
                <Input size="large" placeholder="City" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="state"
                label={<span style={labelStyle}>State</span>}
              >
                <Input size="large" placeholder="State" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="zip_code"
                label={<span style={labelStyle}>Postal / Zip Code</span>}
              >
                <Input size="large" placeholder="Postal / Zip Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Divider
                textPaddingInline={0}
                orientationMargin={0}
                orientation="left"
                plain
                style={{ fontSize: "16px", color: "#23262F" }}
              >
                Coupon Info
              </Divider>
            </Col>
          </Row>
          <Form.Item
            hasFeedback
            id="coupon_code"
            label={<span style={labelStyle}>Coupon Code</span>}
            rules={[
              // {
              //   required: true,
              //   message: "Please Enter Coupon Code!",
              // },
              () => ({
                validator: async (_, value) => {
                  try {
                    const isValid = await checkCouponCode_({
                      coupon_code: value,
                    });

                    setCouponCodeId(isValid?.data?.record?.id || null);
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (isValid?.data?.record?.length !== 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Invalid coupon code, please try again")
                      );
                    }
                  } catch (error) {
                    return Promise.reject(
                      new Error("Validation failed due to API error")
                    );
                  }
                },
              }),
            ]}
            name="coupon_code"
          >
            <Input
              placeholder="Coupon Code"
              allowClear
              enterButton="Apply"
              size="large"
              loading={couponCodeValidationLoading}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <label style={labelStyle}>Captcha</label>
              <Captcha
                setCaptchaStatus={setCaptchaStatus}
                reloadCode={reloadCode}
                captchaStatus={captchaStatus}
                form={form}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <WrapperSignIn>
      <section className="login">
        <div className="login_box fadeInDown">
          <div className="text-center mb-0">
            <h1 className="text-gray-900 fw-bolder mb-3">
              <h1 className="tracking-in-expand-fwd  fw-bold fs-2x mb-3">
                Welcome in <span className="text-primary">AalaCart</span>
              </h1>
            </h1>

            <div className="text-gray-500 fw-semibold fs-6">
              Please enter your account details.
            </div>
          </div>
          {errorView?.show && (
            <Alert
              message={errorView?.message}
              type={errorView?.type}
              closable
              className="mb-5"
              afterClose={() => {
                setErrorView({ show: false, message: "" });
              }}
            />
          )}
          <Form
            form={form}
            name="registrationForm"
            layout="vertical"
            initialValues={{
              contact_code: "1",
              country: "United States",
            }}
          >
            <div
              style={{
                maxHeight: "530px",
                overflow: "auto",
                overflowX: "hidden",
              }}
              className="hover-scroll"
              id="content-box"
            >
              <div>{steps[current]?.content}</div>
            </div>
            <div
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {current < steps.length - 1 && (
                <Form.Item style={{ width: "100%" }}>
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    type="primary"
                    loading={emailValidationLoading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          checkEmail(values);
                        })
                        .catch((info) => {});
                    }}
                  >
                    Next
                  </Button>
                </Form.Item>
              )}

              {current > 0 && (
                <Form.Item>
                  <Button
                    size="large"
                    style={{
                      borderColor: "#898989",
                      color: "#202020",
                    }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                </Form.Item>
              )}
              {current === steps.length - 1 && (
                <Form.Item>
                  <Button
                    loading={signUpLoading}
                    onClick={() => {
                      const element = document.getElementById("content-box");

                      // Scroll to the bottom of this element
                      element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth",
                      });
                      form
                        .validateFields()
                        .then((values) => {
                          const obj = {
                            ...values,
                            email: form?.getFieldValue("email"),
                            type: "seller",
                          };

                          submit(obj);
                        })
                        .catch((info) => {
                          setReloadCode(!reloadCode);
                        });
                    }}
                    size="large"
                    type="primary"
                  >
                    Submit
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
        </div>
      </section>
    </WrapperSignIn>
  );
}

import { Icon } from "@iconify/react/dist/iconify.js";
import { Col, Image, Input, message, Popover, Row, Tag } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Table2 from "../../../../components/tables/table2";
import {
  AddCommas,
  DateFormate,
  PaginationConfig,
  primaryColor,
} from "../../../../config";

const AdvertisementProductAnalytics = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [marketplaceObj, setMarketplaceObj] = useState({
    currency_sign: "₹",
    marketplace_id: "",
  });

  const getList = async (data) => {
    const response = await MakeApiCall(
      `product-analytics/get-product-list?page=${
        data?.page || page || 1
      }&per-page=${data?.pageSize || pageSize}&marketplace_id=A21TJRUUN4KGV`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => list?.length + 1 + index,
    },
    {
      title: "Product",
      dataIndex: "item_name",
      key: "item_name",
      width: 400,
      render: (text, record) => {
        return (
          <div
            style={{
              border: "1px dashed #d9d9d9",
              borderRadius: "8px",
              padding: "5px",
              maxWidth: "400px",
              background: "#fff",
            }}
          >
            <Row align="middle">
              {/* Image Section */}
              <Col span={5}>
                <Image
                  width={50}
                  height={50}
                  src={record?.image_url}
                  alt="Product"
                  style={{
                    borderRadius: "4px",
                  }}
                  preview={false} // Disable the preview functionality
                />
              </Col>

              {/* Text Section */}
              <Col span={18}>
                <Popover
                  content={
                    <div
                      style={{
                        height: "max-content",
                        maxWidth: "300px",
                      }}
                    >
                      {record?.item_name}
                    </div>
                  }
                  placement="bottom"
                >
                  <div>
                    <span
                      className="singleLineEllipsis"
                      style={{ width: "270px" }}
                    >
                      {record?.item_name}
                    </span>
                  </div>
                </Popover>

                <div style={{ marginTop: "8px" }}>
                  <strong>ASIN:</strong>{" "}
                  <span className="text-primary">{record?.asin1}</span> <br />
                  {/* <Divider type="vertical" /> */}
                  <strong>SKU:</strong>{" "}
                  <span className="text-primary">{record?.seller_sku}</span>
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={
            status === "Active"
              ? "success"
              : status === "Inactive"
              ? "error"
              : "orange"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Fulfillment Channel",
      dataIndex: "fulfillment_channel",
      key: "fulfillment_channel",

      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={text === "DEFAULT" ? "orange" : "purple"}
          >
            {text === "DEFAULT" ? "FBM" : "FBA"}
          </Tag>
        );
      },
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
      key: "product_id",
    },
    {
      title: "Sales Price",
      dataIndex: "price",
      key: "price",

      render: (price) => (
        <div>
          <span className="fw-bold">
            {marketplaceObj?.currency_sign}
            {AddCommas(price)}
          </span>
        </div>
      ),
    },
    {
      title: "Stock Quantity",
      dataIndex: "stock_quantity",
      key: "stock_quantity",

      render: (text) => (
        <div>
          <span className="fw-bold">{text}</span>
        </div>
      ),
    },
    {
      title: "Sales Rank",
      dataIndex: "sales_rank",
      key: "sales_rank",
      //   sorter: (a, b) => a.salesRank - b.salesRank, // Optional: sorting by sales rank
    },
    // {
    //   title: "Organic Sales",
    //   dataIndex: "organicSales",
    //   key: "organicSales",
    //
    //   //   render: (text) => `$${text.toLocaleString()}`, // Format as currency
    // },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (data) => (
        <div>
          <span className="">
            {marketplaceObj?.currency_sign}
            {AddCommas(data)}
          </span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "Impression",
      dataIndex: "impressions",
      key: "impressions",
    },
    {
      title: "Ads Unit Sold",
      dataIndex: "ads_unit_sold",
      key: "ads_unit_sold",
    },
    {
      title: "Ads sales ",
      dataIndex: "ads_sales",
      key: "ads_sales",
      render: (data) => (
        <div>
          <span className="">
            {marketplaceObj?.currency_sign}
            {AddCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (text) => {
        return <span> {text || "0"}</span>;
      },
      //   render: (text) => `$${text.toFixed(2)}`, // Format CPC to two decimal places
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
      //   render: (text) => `${text}%`, // Format as percentage
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      render: (data) => (
        <div>
          <span className="">{AddCommas(data)}%</span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Unit Sold",
      dataIndex: "units_sold",
      key: "units_sold",
      render: (data) => (
        <div>
          <span className="">{AddCommas(data)}</span>
        </div>
      ),
    },
    {
      title: "Organic Sales",
      dataIndex: "organic_sales",
      key: "organic_sales",
      render: (data) => (
        <div>
          <span className="">
            {marketplaceObj?.currency_sign}
            {AddCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "TACoS",
      dataIndex: "TACoS",
      key: "TACoS",
      render: (data) => (
        <div>
          <span className="">{AddCommas(data)}%</span>
        </div>
      ),
    },
    // {
    //   title: "Ratings",
    //   dataIndex: "ratings",
    //   key: "ratings",
    // },
    // {
    //   title: "Reviews",
    //   dataIndex: "reviews",
    //   key: "reviews",
    // },

    {
      title: "Campaigns",
      render: (text, record) => {
        return (
          <div
          // style={{
          //   border: "1px dashed #d9d9d9",
          //   borderRadius: "8px",
          //   padding: "5px",
          //   maxWidth: "400px",
          //   background: "#fff",
          // }}
          >
            <Row align="middle">
              <Col span={24}>
                <div className="d-flex align-items-center">
                  {" "}
                  {text?.campaign_name?.length > 0 && (
                    <Icon
                      icon="solar:eye-broken"
                      style={{ color: primaryColor }}
                      width={24}
                      className="me-2"
                      onClick={() => {
                        setSelectedCampaign(text?.campaign_name || []);
                        setCampaignView(true);
                      }}
                    />
                  )}{" "}
                  <Popover
                    content={
                      <div
                        style={{
                          height: "max-content",
                          maxWidth: "200px",
                        }}
                      >
                        {record?.campaign_name?.[0]?.campaign_name}
                      </div>
                    }
                    placement="bottom"
                  >
                    <div>
                      <span
                        className="singleLineEllipsis"
                        style={{ width: "170px" }}
                      >
                        {record?.campaign_name?.[0]?.campaign_name}
                      </span>
                    </div>
                  </Popover>
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },

    {
      title: "Description",
      dataIndex: "item_description",
      key: "item_description",
      render: (text) => {
        return !text ? (
          "-"
        ) : (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "600px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              className="text-dark mb-1 fw-bolder singleLineEllipsis fs-6"
              style={{ width: "150px" }}
            >
              {text || "-"}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => (a.created_at || 0) - (b.created_at || 0),
      render: (e) => {
        return (
          <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at || 0 - b.updated_at || 0,
      render: (e) => {
        return (
          <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
        );
      },
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearchKey("");
    getList({ searchKey: "" });
  };

  return (
    <>
      <div className="card  mb-5 mb-xl-8">
        <div className="card-header  py-2 px-3">
          <div className="card-title">
            <Input
              onChange={(e) => {
                setSearchKey((prev) => {
                  if (!e.target.value && prev) {
                    ClearSearch();
                  }
                  return e.target.value;
                });
              }}
              onPressEnter={() => {
                setLoading(true);
                getList({ searchKey: searchKey });
              }}
              value={searchKey}
              placeholder="Search by name"
              prefix={<Icon icon="clarity:search-line" />}
            />
          </div>
          <div className="card-toolbar"></div>
        </div>

        <div className="card-body  px-3">
          <Table2
            columns={columns}
            pagination={PaginationConfig({
              pageSize,
              page,
              totalPage,
              onChange: (e, f) => {
                setLoading(true);

                setPageSize(f);
                setPage(e);
                getList({
                  page: e,
                  pageSize: f,
                  searchKey: searchKey,
                });
              },
            })}
            dataSource={list}
            loading={loading}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </>
  );
};

export default AdvertisementProductAnalytics;

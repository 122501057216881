import { Tabs } from "antd";
import React from "react";
import EventTypes from "./lib/event-types";
import UserLogs from "./lib/user-logs";
import { Wrapper } from "./style";

const { TabPane } = Tabs;

const SellerActivity = () => {
  return (
    <Wrapper>
      <>
        <Tabs
          tabBarStyle={{ marginBottom: 0, padding: "0px 15px" }}
          type="card"
        >
          <TabPane tab={<span className="px-4">User Logs</span>} key="1">
            <div className="card px-3">
              <UserLogs />
            </div>
          </TabPane>
          <TabPane tab={<span className="px-4">Event Types</span>} key="2">
            <div className="card px-3">
              <EventTypes />
            </div>
          </TabPane>
        </Tabs>
      </>
    </Wrapper>
  );
};

export default SellerActivity;

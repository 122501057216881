import { Icon } from "@iconify/react/dist/iconify.js";
import { notification, Skeleton } from "antd";
import { MakeApiCall } from "../apis";
import Pagination from "../components/pagination";

export const ProjectName = "AalaCart";
export const DefaultAdminPage = `/manage-user`;
export const DefaultUserPage = `/dashboard`;
export const DateFormate = "MMM DD, YYYY";
export const ORIGIN = `${
  process.env.NODE_ENV === "development"
    ? "https://api.aalacart.com"
    : "https://api.aalacart.com"
}`;
export const ENDPOINT = `${ORIGIN}/api/v1/`;
export const Types = {
  1: "admin",
  3: "user",
};

export const primaryColor = "#3699ff"; // medium
export const secondaryColor = "#3e3e3e"; // dark
export const thirdColor = "#3699ff"; // light
export const forthColor = "#cfe3f7"; // light

export const BorderFilter = `1px solid ${primaryColor}`;
export const FormatUserName = (userName) => {
  // Replace hyphens with spaces
  let formattedName = userName?.replace(/-/g, " ");

  // Capitalize the first letter of each word
  formattedName = formattedName?.replace(/\b\w/g, function (char) {
    return char?.toUpperCase();
  });

  return formattedName === "Sku" ? "SKU" : formattedName;
};
export const DefaultPerPage = 20;
export const pageDropdown = [10, 20, 50, 100];
export const RenderTable = (props, row, index, d, width) =>
  typeof d?.render === "function" ? (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        {d?.render(props, row, index)}
      </div>
    </>
  ) : (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        <span>{props || "-"}</span>
      </div>
    </>
  );
export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(
        ([_, value]) =>
          value &&
          value !== "" &&
          value !== undefined &&
          value !== null &&
          value?.length !== 0
      )
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);

        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};
export const AddCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const PaginationConfig = (props) => {
  const {
    table = true,
    pageSize,
    currentPage,
    page,
    totalPage,
    onChange = () => {},
  } = props;
  console.log(page, "currentPage");

  if (!table) {
    return (
      <Pagination
        pageSize={pageSize}
        page={page}
        totalPage={totalPage}
        onPerPage={(e, r) => {
          onChange(r, e);
        }}
        onPageNo={(e, t) => {
          onChange(e, t);
        }}
      />
    );
  }
  return {
    pageSize: pageSize,
    current: page,
    total: totalPage,
    onChange: (e, f) => {
      onChange(e, f);
    },
    showSizeChanger: true, // To show page size options
    pageSizeOptions: [10, 20, 50, 100], // Page size options
    showTotal: (total, range) =>
      `${AddCommas(range[0] || 0)}-${AddCommas(range[1] || 0)} of ${AddCommas(
        total || 0
      )} items`,
  };
};
export const DisplayNotification = (
  type,
  title,
  message,
  placement,
  closeIcon
) => {
  notification.destroy();
  setTimeout(() => {
    if (type === "error") {
      notification.error({
        message: title || "Error",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#ffb7b7", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "success") {
      notification.success({
        message: title || "Error",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#cdefcb", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "warning") {
      notification.warning({
        message: title || "Warning",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#fef5e6", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "info") {
      notification.info({
        message: title || "Info",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#d8e7ff", // Change the background color
          borderRadius: "7px",
        },
      });
    }
  }, 500);
};
export const getTableLoading = (numberOfColumns) => {
  const numberOfRows = 10;
  const renderedRows = [...Array(numberOfRows)].map((e, i) => (
    <tr key={i}>
      {[...Array(numberOfColumns)]?.map((d) => {
        return (
          <td>
            <Skeleton.Button active block />
          </td>
        );
      })}
    </tr>
  ));
  return renderedRows;
};

export const isNot2000SeriesYear = (date) => {
  const year = new Date(date).getFullYear();
  return year < 2000 || year > 2099;
};

export const EditIcon = () => {
  return (
    <Icon
      icon="basil:edit-outline"
      style={{ color: primaryColor }}
      width={24}
    />
  );
};
export const DeleteIcon = (props) => {
  const { width } = props;
  return (
    <Icon
      icon="fluent:delete-20-filled"
      style={{ color: "#ff5454" }}
      width={width || 24}
    />
  );
};
export const PlusIcon = (props) => {
  const { width = 24 } = props;
  return (
    <Icon
      icon="ic:baseline-plus"
      style={{ color: primaryColor }}
      width={width || 24}
    />
  );
};
export const GetMarketplaceList = (marketplaceList) =>
  marketplaceList.map((d, i) => {
    return {
      label: (
        <>
          <img
            src={`/assets/media/domainImage/${d?.marketplace_id}.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/assets/media/domainImage/red-flag.png";
            }}
          />
          {d?.marketplace}({d?.sales_channel})
        </>
      ),
      value: d?.marketplace_id,
    };
  });
export const GetMarketplaceDropdown = async () => {
  try {
    const response = await MakeApiCall(
      "marketplaces/get-marketplace-by-credential",
      "get",
      null,
      true
    );

    if (response?.status) {
      return {
        marketplace_list: response?.data?.marketplace_list,
        currency_list: response?.data?.currency_symbols,
      };
    } else {
      // Handle error case and notify
      DisplayNotification(
        "error",
        "Error",
        response?.message || "Unknown error occurred"
      );
      return null; // Use null to signify no data
    }
  } catch (error) {
    // Catch any unexpected errors
    DisplayNotification(
      "error",
      "Error",
      error.message || "An error occurred while fetching data"
    );
    return null; // Use null to signify no data
  }
};

import AdDefinitions from "../modules/admin/ad-definition";
import CouponCodes from "../modules/admin/coupon-codes";
import AdminDataSchedulerLog from "../modules/admin/data-scheduler";
import EmailDefination from "../modules/admin/email-definations";
import SellerActivity from "../modules/admin/seller-activity";
import UserList from "../modules/admin/user-list";

export const Admins = [
  {
    path: "manage-user",
    element: <UserList />,
  },
  {
    path: "email-definations",
    element: <EmailDefination />,
  },
  {
    path: "coupon-codes",
    element: <CouponCodes />,
  },
  {
    path: "ad-defination",
    element: <AdDefinitions />,
  },
  {
    path: "seller-activity",
    element: <SellerActivity />,
  },
  {
    path: "/admin-data-scheduler",
    element: <AdminDataSchedulerLog />,
  },
];

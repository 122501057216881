export const AdminMenus = [
  {
    label: "Manage User",
    key: "manage-user",
    icon: "mage:users-fill",
  },
  {
    label: "Email Definations",
    key: "email-definations",
    icon: "mdi:email-edit",
  },
  {
    label: "Coupon Codes",
    key: "coupon-codes",
    icon: "ri:coupon-3-fill",
  },
  {
    label: "Ad Defination",
    key: "ad-defination",
    icon: "lsicon:report-filled",
  },
  {
    label: "Seller Activity",
    key: "seller-activity",
    icon: "fa6-solid:users-line",
  },
  {
    label: "Data Scheduler",
    key: "admin-data-scheduler",
    icon: "academicons:open-data",
  },
];

import { Col, Modal, Row, Table } from "antd";
import React from "react";
import dayjs from "dayjs";
import NoDataTable from "../../../../../../components/no-data-table";
import { TableLoading } from "../../../../../../components/table-animation";
import { PaginationConfig } from "../../../../../../config";

export default function (props) {
  const {
    show,
    onHide,
    data,
    subLoading,
    setSubLoading,
    subTotalPage,
    success_failed_all_filters,
    set_success_failed_all_filters,
    GetSuccessFailedCount,
  } = props;

  const columns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (text, __, i) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>
              {(success_failed_all_filters?.page - 1) *
                success_failed_all_filters?.["per-page"] +
                1 +
                i}
            </span>
            <span className="ms-5">
              {text?.new_value > text?.old_value ? (
                <label className="fw-bolder">
                  <i
                    class="ki-duotone ki-double-up fs-1"
                    style={{ color: "green" }}
                  >
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                  </i>
                </label>
              ) : (
                <label className="fw-bolder">
                  <i
                    class="ki-duotone ki-double-down fs-1"
                    style={{ color: "red" }}
                  >
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                  </i>
                </label>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    // {
    //   title: "Target Type",
    //   dataIndex: "target_type",
    //   key: "target_type",
    // },
    {
      title: "Change",
      dataIndex: "change_type",
      key: "change_type",
      width: 100,
    },
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: 200,
    },
    {
      title: "AdGroup",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
      width: 100,
    },
    {
      title: "Rule",
      dataIndex: "rule_name",
      key: "rule_name",
      width: 150,
    },
    {
      title: "Target Name",
      dataIndex: "targeting_text",
      key: "targeting_text",
      width: 200,
      render: (text) => {
        return (
          <label className="fw-bolder" style={{ textTransform: "capitalize" }}>
            {text}
          </label>
        );
      },
    },
    {
      title: "Rule Applied Time",
      width: 200,
      render: (text) => {
        if (!text?.rule_applied_time) {
          return "-";
        }
        return (
          <span>
            {dayjs(parseInt(text?.rule_applied_time) * 1000).format(
              "MMM DD, YYYY"
            )}
          </span>
        );
      },
    },
    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
      width: 100,
      render: (text) => {
        return <span>${text}</span>;
      },
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
      width: 120,
      render: (text) => {
        return <span>${text}</span>;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
      width: 300,
    },
  ];
  return (
    <>
      <Modal
        open={show}
        onCancel={onHide}
        width="70%"
        centered
        maskClosable={false}
        title="Action Logs"
        footer={[]}
      >
        <Row>
          <Col span={24}>
            {subLoading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                row={4}
                actions={[1, 2]}
                style={{ height: "290px", overflow: "hidden" }}
              />
            ) : data?.length > 0 ? (
              <Table
                columns={columns}
                sticky={{
                  offsetHeader: 10,
                }}
                scroll={{
                  x: "max-content",
                  y: "calc(100vh - 270px)",
                }}
                dataSource={data}
                rowKey="key"
                loading={subLoading}
                pagination={PaginationConfig({
                  pageSize: success_failed_all_filters?.["per-page"],
                  page: success_failed_all_filters?.page,
                  totalPage:
                    subTotalPage *
                    (success_failed_all_filters?.["per-page"] || 10),
                  onChange: (e, f) => {
                    set_success_failed_all_filters({
                      ...success_failed_all_filters,
                      page: e,
                      "per-page": f,
                    });
                    setSubLoading(true);
                    const apiObj = {
                      ...success_failed_all_filters,
                      page: e,
                      "per-page": f,
                    };
                    GetSuccessFailedCount(apiObj);
                  },
                })}
              />
            ) : (
              <NoDataTable columns={columns} type="no-data" />
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
}

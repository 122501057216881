import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Dropdown, Image, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddBTN } from "../../../components/button";
import { primaryColor, thirdColor } from "../../../config";
import { fakeImage } from "../../../config/fakeData";
import { ProfileView } from "../header/style";
import { FindMenus } from "./menus";
import { Wrapper } from "./style";

export default function Sidebar({
  collapsed,
  user,
  type,
  location,
  setData,
  handleLogout,
  setCollapsed,
}) {
  const [current, setCurrent] = useState(location?.pathname?.replace("/", ""));
  const { email, name } = user?.user?.user_data;
  const navigate = useNavigate();

  useEffect(() => {
    setCurrent(location?.pathname?.replace("/", ""));
  }, [location]);

  const menu = (
    <Menu>
      <Menu.Item key="6">
        <div className="menu-item">
          <div className="menu-content d-flex align-items-center">
            <div className="symbol symbol-50px me-2">
              <img style={{ width: "50px" }} alt="Logo" src={fakeImage} />
            </div>

            <div className="d-grid ">
              <div className="NameFooter fw-bold d-flex align-items-center fs-5">
                {name}
              </div>
              <span className="EmailFooter fw-semibold text-muted text-hover-primary fs-7">
                {email}
              </span>
            </div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item key="4">
        <AddBTN
          onClick={handleLogout}
          style={{ height: "38px", width: "100%", marginTop: "4px" }}
          size="small"
          type="primary"
        >
          Sign Out
        </AddBTN>
      </Menu.Item>
    </Menu>
  );
  return (
    <Wrapper
      className="slideInLeft"
      width={260}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div
        style={{
          position: "absolute",
          right: collapsed ? "25px" : "-10px",
          background: "rgb(15 25 38)",
          margin: "auto",
          height: "30px",
          width: "30px",
          top: collapsed ? "67px" : "21px",
          borderRadius: "11px",
          transform: collapsed ? "rotate(180deg)" : "rotate(0deg)",
        }}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <Icon icon="weui:arrow-filled" width={14} style={{ color: "#FFF" }} />
      </div>
      <div className="demo-logo-vertical shimmer">
        <img
          style={{ width: collapsed ? "50px" : "180px", marginBottom: "10px" }}
          alt="Logo"
          src={collapsed ? "/assets/favicon.jpg" : "/assets/full-logo.png"}
        />
      </div>

      <div
        className="sidebar-view hover-scroll"
        style={{ marginTop: collapsed ? "65px" : 0 }}
      >
        <Menu
          className=" mt-5"
          selectedKeys={[current]}
          onClick={(e) => {
            setCurrent(e?.key);
          }}
          theme="light"
          defaultOpenKeys={["inventory"]}
          style={{
            position: "relative",
            right: collapsed ? "0px" : "0px",
            top: collapsed ? "20px" : "0px",
          }}
          mode="inline"
          items={FindMenus(type)}
        />
      </div>
      <div
        className={`d-flex align-items-center justify-content-center ${
          !collapsed ? "mt-10" : ""
        }`}
      >
        <Image
          style={{ mixBlendMode: "darken" }}
          className="me-3"
          src={fakeImage}
          width={60}
        />
        <div
          style={{
            position: "relative",
            display: !collapsed ? "block" : "none",
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{
              background: "#FFF",
              padding: "10px 30px 10px 15px",
              borderRadius: "10px",
              border: "1px solid rgb(15 25 38)",
            }}
          >
            <Button size="large" type="text" style={{ padding: 0 }}>
              <ProfileView
                className="d-grid"
                style={{ lineHeight: "18px", justifyItems: "start" }}
              >
                <div className="EmailFooter">Welcome back,</div>
                <div className="NameFooter">{name}</div>
              </ProfileView>
            </Button>
          </div>

          <div
            style={{
              position: "absolute",
              right: "-10px",
              background: "rgb(15 25 38)",
              margin: "auto",
              height: "23px",
              width: "23px",
              top: "10px",
              borderRadius: "30px",
              border: "3px solid white",
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <Dropdown trigger={["click"]} overlay={menu} placement="rightTop">
              <Icon
                icon="weui:arrow-filled"
                width={10}
                style={{ color: "#FFF" }}
              />
            </Dropdown>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import { Tabs } from "antd";
import React, { useState } from "react";
import AdvertisementCampaignList from "./campaign-list";
import { Wrapper } from "./style";
import AdvertisementCampaignCreation from "./campaign-creation";

const AdvertisementCampaignManagement = () => {
  const [activeTab, setActiveTab] = useState("campaign_list");
  return (
    <Wrapper>
      <>
        <Tabs
          tabBarStyle={{ marginBottom: 0, padding: "0px 15px" }}
          type="card"
          items={[
            {
              key: "campaign_list",
              label: "Campaign List",
            },
            {
              key: "campaign_creation",
              label: "Campaign Creation",
            },
          ]}
          onChange={(key) => {
            setActiveTab(key);
          }}
          defaultActiveKey="campaign_list"
        />

        {activeTab === "campaign_list" && <AdvertisementCampaignList />}
        {activeTab === "campaign_creation" && <AdvertisementCampaignCreation />}
      </>
    </Wrapper>
  );
};

export default AdvertisementCampaignManagement;

import { Button, Checkbox, Col, Flex, Popover, Row, Select, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";

import dayjs from "dayjs";
// import { GlobalContext } from "../../../../../commonContext";
// import EsfTable from "../../../../../component/esf-table";
// import Pagination from "../../../../../component/esf-table/lib/pagination";
// import NoDataTable from "../../../../../component/no-data-table";

import { MakeApiCall } from "../../../apis";
// import UpdateSchedulerModal from "./lib/update-scheduler-modal";
import {
  DisplayNotification,
  ConvertParams,
  PaginationConfig,
} from "../../../config";
import Table2 from "../../../components/tables/table2";
import NoDataTable from "../../../components/no-data-table";
import { a } from "react-spring";
import UpdateSchedulerModal from "./lib/update-scheduler-modal";

const AdminDataSchedulerLog = () => {
  const [loading, setLoading] = useState(false);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [event_list, set_event_list] = useState({});
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
    search: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllRows, setIsAllRows] = useState(false);
  const [user_list, set_user_list] = useState([]);
  const [isOpenUdpateModal, setIsOpenUdpateModal] = useState(false);

  useEffect(() => {
    GetDataSchedulerLogList({
      ...all_filters,
    });
    GetDataSchedulerEventNameList();
    GetAllUsersList();
    return () => {};
  }, []);

  console.log(user_list, "user_list");

  const GetDataSchedulerLogList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `admin-data-scheduler${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetDataSchedulerEventNameList = async () => {
    setLoading(true);
    const response = await MakeApiCall(
      `admin-data-scheduler-event-list`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      // const newData = Object.entries(response?.data)?.map((d) => {
      //   return d?.[0];
      // });
      set_event_list(response?.data);
    } else {
      set_event_list([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetAllUsersList = async () => {
    const response = await MakeApiCall(`user/get-all-user`, `get`, null, true);
    if (response?.status) {
      set_user_list(response?.data);
    } else {
      set_user_list([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const columns = [
    {
      title: () => {
        return (
          <Checkbox
            checked={isAllRows}
            size="large"
            onChange={(e) => {
              if (e.target.checked) {
                const allRows = list?.map((d) => d?.id);
                setSelectedRows(allRows);
              } else {
                setSelectedRows([]);
              }
              setIsAllRows(e.target.checked);
            }}
          />
        );
      },
      render: (text) => (
        <Checkbox
          checked={
            selectedRows.filter((a) => a == text?.id)?.length == 1
              ? true
              : false
          }
          size="large"
          onChange={(e) => {
            const checkboxId = text?.id;
            setSelectedRows((prev) => {
              if (e?.target?.checked) {
                return [...prev, checkboxId];
              } else {
                return prev.filter((id) => id !== checkboxId);
              }
            });
          }}
        />
      ),
    },
    {
      title: "#",
      render: (__, _, key) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + key}
        </span>
      ),
    },
    {
      title: "Event Process Type",
      dataIndex: "event_process_type",
      key: "event_process_type",
      width: 150,
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
      width: 150,
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
      width: 300,
      render: (text, record) => (
        <Popover
          content={
            <div style={{ height: "max-content", maxWidth: "400px" }}>
              {record?.event_name}
            </div>
          }
          placement="bottomLeft"
        >
          <div className="mb-1 text-one fs-6 popoverActionIconSingleLine">
            <b className="fw-bolder">{record?.event_name}</b>
          </div>
        </Popover>
      ),
    },
    {
      title: "Event Label",
      dataIndex: "event_label",
      key: "event_label",
      width: 200,
    },
    {
      title: "Previous Days",
      dataIndex: "previous_days",
      key: "previous_days",
      width: 100,
    },
    {
      title: "Previous Status",
      dataIndex: "previous_status",
      key: "previous_status",
      width: 100,
      render: (text) => (
        <Tag
          color={text === "DONE" ? "#389e0d" : "#c69d2d"}
          className="fw-bold"
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Update Daily Days",
      dataIndex: "update_daily_days",
      key: "update_daily_days",
      width: 150,
    },
    {
      title: "Update Daily Status",
      dataIndex: "update_daily_status",
      key: "update_daily_status",
      width: 150,
      render: (text) => (
        <Tag
          color={text === "DONE" ? "#389e0d" : "#c69d2d"}
          className="fw-bold"
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text) => (
        <Tag color={text === 1 ? "green" : "red"} className="fw-bold">
          {text === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
    },

    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <span>
          {dayjs(new Date(created_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({dayjs(new Date(created_at * 1000)).fromNow()})
          </small>
        </span>
      ),
      width: 190,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <span>
          {dayjs(new Date(updated_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({dayjs(new Date(updated_at * 1000)).fromNow()})
          </small>
        </span>
      ),
      width: 190,
    },
  ];

  return (
    <>
      <>
        <Row>
          <Col span={24}>
            <div className="card px-3  mb-5 mb-xl-8">
              <div className="card-header border-0">
                <div class="card-title d-flex align-items-center">
                  Data Scheduler
                </div>

                <div className="card-toolbar">
                  <Flex gap={5} align="center">
                    <div className="position-relative ">
                      <Select
                        className="w-250px"
                        size="middle"
                        placeholder="Event Process Type"
                        allowClear
                        value={all_filters?.event_process_type || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            event_process_type: e,
                          };
                          set_all_filters(apiObj);
                          GetDataSchedulerLogList({
                            ...apiObj,
                          });
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_process_type || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>{" "}
                    <div className="position-relative ">
                      <Select
                        className="w-250px"
                        size="middle"
                        placeholder="Event Type"
                        allowClear
                        value={all_filters?.event_type || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            event_type: e,
                          };
                          set_all_filters(apiObj);
                          GetDataSchedulerLogList({
                            ...apiObj,
                          });
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_type || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>
                    <div className="position-relative">
                      <Select
                        className="w-250px"
                        size="middle"
                        placeholder="Event Name"
                        allowClear
                        value={all_filters?.event_name || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            event_name: e,
                          };
                          set_all_filters(apiObj);
                          GetDataSchedulerLogList({
                            ...apiObj,
                          });
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_name || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setIsOpenUdpateModal(true);
                      }}
                      disabled={selectedRows?.length === 0}
                    >
                      Update Data Scheduler
                    </Button>
                  </Flex>
                </div>
              </div>

              <div className="card-body py-2">
                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : list?.length > 0 ? (
                    <Table2
                      columns={columns?.map((d) => ({
                        ...d,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 320px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={PaginationConfig({
                        pageSize: all_filters?.["per-page"],
                        page: all_filters?.page,
                        totalPage: totalPage,
                        onChange: (e, f) => {
                          set_all_filters({
                            ...all_filters,
                            page: e,
                            "per-page": f,
                          });
                          GetDataSchedulerLogList({
                            ...all_filters,
                            page: e,
                            "per-page": f,
                          });
                        },
                      })}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {isOpenUdpateModal && (
          <UpdateSchedulerModal
            show={isOpenUdpateModal}
            onHide={() => {
              setIsOpenUdpateModal(false);
            }}
            user_list={user_list}
            selected_rows={selectedRows}
          />
        )}
      </>
    </>
  );
};

export default AdminDataSchedulerLog;

import { Typography } from "antd";
import React, { useState } from "react";
import AutomaticNegativeTargeting from "./auto-negative-targeting/automatic-negative-targeting";
import NegativeKeywordTargeting from "./negative-keyword-targeting";
import NegativeProductTargeting from "./negative-product-targeting";
const { Text } = Typography;

const NegativeTargeting = (props) => {
  const [negative_type, set_negative_type] = useState("keyword");

  return (
    <>
      {negative_type === "keyword" && <NegativeKeywordTargeting {...props} />}
      {negative_type === "product" && <NegativeProductTargeting {...props} />}
      {negative_type === "automatic" && (
        <AutomaticNegativeTargeting {...props} />
      )}
    </>
  );
};

export default NegativeTargeting;

import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Col, Pagination, Popover, Row, Table } from "antd";
import NoDataTable from "../../../../components/no-data-table";
import { MakeApiCall } from "../../../../apis";
import { ConvertParams, DisplayNotification } from "../../../../config";
import dayjs from "dayjs";

const SystemErrorLog = () => {
  const [loading, setLoading] = useState(false);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": 20,
    search_key: "",
  });

  useEffect(() => {
    GetSystemErrorLogList(all_filters);
  }, []);

  const GetSystemErrorLogList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `user/system-error-log${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    GetSystemErrorLogList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    GetSystemErrorLogList(apiObj);
  };

  const columns = [
    {
      title: "#",
      render: (text, _, index) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + index}
        </span>
      ),
    },
    {
      title: "Log Created at",
      width: 200,
      render: (record) => (
        <span>
          {dayjs(new Date(record?.created_at * 1000)).format(
            "MMM DD, YYYY hh:mm A"
          )}
        </span>
      ),
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
      width: 300,
      render: (text, record) => (
        <Popover
          content={
            <div style={{ height: "max-content", maxWidth: "400px" }}>
              {record?.event_name}
            </div>
          }
          placement="bottomLeft"
        >
          <div className="mb-1 text-one fs-6 popoverActionIconSingleLine">
            <span className="fw-bolder">{record?.event_name}</span>
          </div>
        </Popover>
      ),
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
      width: 200,
      render: (text, record) => (
        <span className="fw-bolder">{record?.event_type || "-"}</span>
      ),
    },
    {
      title: "Error Message",
      dataIndex: "error_message",
      key: "error_message",
      width: 400,
      render: (text, record) => (
        <Popover
          content={
            <div style={{ height: "max-content", maxWidth: "400px" }}>
              {record?.error_message}
            </div>
          }
          placement="bottomLeft"
        >
          <div
            className="mb-1 text-one fs-6 popoverActionIconTwoLine"
            style={{ minWidth: "100px" }}
          >
            {record?.error_message}
          </div>
        </Popover>
      ),
    },
    {
      title: "Error Data",
      dataIndex: "error_data",
      key: "error_data",
      width: 400,
      render: (text, record) => (
        <Popover
          content={
            <div
              style={{
                height: "400px",
                maxWidth: "600px",
                overflowY: "auto",
                wordBreak: "break-all",
              }}
            >
              {record?.error_data}
            </div>
          }
          placement="bottomLeft"
        >
          <div className="mb-1 text-one fs-6 popoverActionIconTwoLine">
            {record?.error_data || "-"}
          </div>
        </Popover>
      ),
    },
  ];
  return (
    <Wrapper>
      <Row>
        <Col span={24}>
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold fs-3 mb-1">
                  System Error Log
                </span>
              </h3>
            </div>
            <div className="separator separator-dashed"></div>

            <div className="card-body py-2">
              <div className="table-responsive">
                {loading ? (
                  <NoDataTable columns={columns} type="loading" />
                ) : list?.length > 0 ? (
                  <Table
                    columns={columns}
                    sticky={{
                      offsetHeader: "10px",
                    }}
                    scroll={{
                      x: "max-content",
                      y: "calc(100vh - 320px)",
                    }}
                    fixed="top"
                    dataSource={list}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                  />
                ) : (
                  <>
                    <NoDataTable columns={columns} type="no-data" />
                  </>
                )}
              </div>
              <Pagination
                loading={loading || list?.length === 0}
                pageSize={all_filters?.["per-page"]}
                page={all_filters?.page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SystemErrorLog;

import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { DeleteIcon, PlusIcon } from "../../../../../../../../../../config";
import CategoryTargeting from "./category-targeting";
import IndividualProductTargeting from "./individual-product-targeting/individual-product-targeting";
// import EnterList from "./enter-list";
// import SearchItem from "./search-item";
const { Text } = Typography;

const ManualProductTargeting = (props) => {
  const [activeTab, setActiveTab] = useState("category");
  return (
    <>
      <Row>
        <Col span={24} className="">
          {" "}
          <Tabs
            tabBarStyle={{ marginBottom: 0, padding: "0px 15px" }}
            type="card"
            items={[
              {
                key: "category",
                label: "Category",
              },
              {
                key: "individual_product",
                label: "Individual Product",
              },
            ]}
            onChange={(key) => {
              setActiveTab(key);
            }}
            defaultActiveKey={activeTab}
          />
        </Col>
      </Row>
      {activeTab === "category" && <CategoryTargeting {...props} />}
      {activeTab === "individual_product" && (
        <IndividualProductTargeting {...props} />
      )}
    </>
  );
};

export default ManualProductTargeting;

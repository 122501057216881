
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Card,
  Col,
  Divider,
  Input,
  message,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { DeleteIcon, secondaryColor } from "../../../../../../../../config";
import Table2 from "../../../../../../../../components/tables/table2";
const { Text } = Typography;
const AddAdgroup = (props) => {
  const {} = props;
  const [adgroupInput, setAdgroupInput] = useState("");
  const [selectedAdgroupList, setSelectedAdgroupList] = useState([
    "test",
    "test2",
  ]);

  const bidByTargetingColumns = [
    {
      title: "Targeting Groups",
      dataIndex: "targetingGroups",
      key: "targetingGroups",
    },
    {
      title: "Suggested Bid for Regular Days",
      dataIndex: "suggestedBid",
      key: "suggestedBid",
    },
    {
      title: "Bid",
      dataIndex: "bid",
      key: "bid",
    },
  ];
  return (
    <Row gutter={[0, 16]}>
      <Col span={24} className="">
        <Card
          title={
            <Title level={5} className="mb-0">
              Ad Group
            </Title>
          }
        >
          <Row>
            <Col span={24}>
              <Text>Ad Group Name</Text>
              <br />
              <Input
                placeholder="Enter Ad Group Name"
                style={{ width: "300px" }}
                value={adgroupInput}
                disabled={selectedAdgroupList?.length > 0}
                onPressEnter={(e) => {
                  if (e.target.value) {
                    const isExistAdgoup = selectedAdgroupList?.filter(
                      (f) => f === e.target.value
                    );

                    if (isExistAdgoup?.length > 0) {
                      message.destroy();
                      message.info("Ad group already exist");
                    } else {
                      setSelectedAdgroupList([
                        ...selectedAdgroupList,
                        e.target.value,
                      ]);
                      setTimeout(() => {
                        setAdgroupInput("");
                      }, 600);
                    }
                  } else {
                    message.destroy();
                    message.error("Please add ad group");
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value) {
                    const isExistAdgoup = selectedAdgroupList?.filter(
                      (f) => f === e.target.value
                    );
                    console.log(isExistAdgoup, "-isExistAdgoup");
                    if (isExistAdgoup?.length > 0) {
                      message.destroy();
                      message.info("Ad group already exist");
                    } else {
                      setSelectedAdgroupList([
                        ...selectedAdgroupList,
                        e.target.value,
                      ]);
                      setTimeout(() => {
                        setAdgroupInput("");
                      }, 600);
                    }
                  } else {
                    message.destroy();
                    message.error("Please add ad group");
                  }
                }}
                onChange={(e) => {
                  setAdgroupInput(e.target.value);
                }}
              />
            </Col>
          </Row>

          {selectedAdgroupList?.length > 0 && (
            <>
              <Divider variant="dashed" ClassName="my-3" />
              <Row gutter={16} className="mb-2">
                <Col span={24}>
                  <Text>Ad Group List</Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} className="d-flex">
                  {selectedAdgroupList?.map((d, i) => {
                    return (
                      <div className="d-flex align-items-center">
                        <Tag
                          color="geekblue"
                          className="d-flex align-items-center justify-content-start me-0"
                        >
                          {d}
                          <span
                            className="d-flex align-items-center ms-2"
                            onClick={() => {
                              const removeAdGroup = selectedAdgroupList?.filter(
                                (f, index) => index !== i
                              );

                              setSelectedAdgroupList(removeAdGroup);
                            }}
                          >
                            <DeleteIcon width="18" />
                          </span>
                        </Tag>

                        <Divider orientation="right" type="vertical" />
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Col>

      <Col span={24} className="">
        <Card
          title={
            <Title level={5} className="mb-0">
              Auto Targeting
            </Title>
          }
        >
          <Row>
            <Col>
              <Radio.Group>
                <Radio value={1}>
                  <div>
                    <Text strong>Set Default Bid</Text>
                  </div>
                </Radio>
                <Radio value={2}>
                  <div>
                    <Text strong>Set bids by targeting groups</Text>
                  </div>
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Divider variant="dashed" className="my-2" />
          <Row gutter={16}>
            <Col span={8}>
              <Text strong>Suggested bid for regular days</Text>
            </Col>
            <Col span={8}>
              <Text strong>$1.49</Text>
              <br />
              <Text>($0.87 - $2.15)</Text>
            </Col>
            <Col span={8}>
              <Input prefix={<Text strong>$</Text>} className="w-150px" />
            </Col>
          </Row>

          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <Table2
                dataSource={[]}
                loading={false}
                scroll={{ x: "max-content" }}
                columns={bidByTargetingColumns}
              />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24} className="">
        <Card
          title={
            <Title level={5} className="mb-0">
              Campaign Bidding Strategy
            </Title>
          }
        >
          <Row>
            <Col>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={1}>
                    <div>
                      <Text strong>Dynamic bids - down only</Text>
                      <br />
                      <Text type="secondary">
                        Lowers your bids in real time when your ad may be less
                        likely to convert to a sale. Any campaign created before
                        October 2018 uses this setting by default.
                      </Text>
                    </div>
                  </Radio>
                  <Radio value={2}>
                    <div>
                      <Text strong>Dynamic bids - up and down</Text>
                      <br />
                      <Text type="secondary">
                        Increases your bids (by a maximum of 100%) in real time
                        when your ad may be more likely to convert to a sale,
                        and lowers your bids when less likely to convert to a
                        sale.
                      </Text>
                    </div>
                  </Radio>
                  <Radio value={3}>
                    <div>
                      <Text strong>Fixed bid</Text>
                      <br />
                      <Text type="secondary">
                        Uses your exact bid and any manual adjustments you set,
                        and is not subject to adjusted bidding.
                      </Text>
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24} className="">
        <Card
          title={
            <Title level={5} className="mb-0">
              Adjust Bids by Placement
            </Title>
          }
        >
          <Row gutter={[16]}>
            <Col span={8}>
              <Text strong>Top of search (First Page)</Text>
            </Col>
            <Col span={4}>
              <Input
                suffix={
                  <Icon
                    icon="material-symbols:percent"
                    width={20}
                    style={{ color: secondaryColor }}
                  />
                }
              />
            </Col>
            <Col span={12}>
              <Text>
                Example: A₹14.75bid will remain₹14.75for this placement, Dynamic
                bidding could increase it up to₹29.5
              </Text>
            </Col>
          </Row>
          <Row gutter={[16]} className="my-3">
            <Col span={8}>
              <Text strong>Rest of search</Text>
            </Col>
            <Col span={4}>
              <Input
                suffix={
                  <Icon
                    icon="material-symbols:percent"
                    width={20}
                    style={{ color: secondaryColor }}
                  />
                }
              />
            </Col>
            <Col span={12}>
              <Text>
                Example: A₹14.75 bid will remain₹14.75 for this placement,
                Dynamic bidding could increase it up to ₹22.13
              </Text>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Text strong>Product Pages</Text>
            </Col>
            <Col span={4}>
              <Input
                suffix={
                  <Icon
                    icon="material-symbols:percent"
                    width={20}
                    style={{ color: secondaryColor }}
                  />
                }
              />
            </Col>
            <Col span={12}>
              <Text>
                Example: A ₹14.75 bid will remain ₹14.75for this placement,
                Dynamic bidding could increase it up to ₹22.13
              </Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default AddAdgroup;

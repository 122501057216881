import { Button, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const EnterList = () => {
  return (
    <Row>
      <Col span={24}>
        <TextArea rows={8} placeholder="Enter ASIN's separated by comma" />
      </Col>
      <Col span={24} className="mt-5">
        <Button type="primary">Add</Button>
      </Col>
    </Row>
  );
};

export default EnterList;

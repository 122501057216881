import { message, Table } from "antd";
import React, { useEffect, useState } from "react";

import { MakeApiCall } from "../../../../apis";
import { PaginationConfig } from "../../../../config";
import Table1 from "../../../../components/tables/table1";
import Table2 from "../../../../components/tables/table2";

const UserLogs = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const GetList = async (data) => {
    const response = await MakeApiCall(
      `seller-activity/user-logs?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    GetList({ ...obj });
  };

  const columns = [
    {
      title: "ID",

      render: (text, record, index) => (page - 1) * pageSize + 1 + index,
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",
      key: "seller_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      filterIndex: "email",
      key: "email",
    },
    {
      title: "Event ID",
      dataIndex: "event_type_id",
      filterIndex: "event_type_id",
      key: "event_type_id",
    },
    {
      title: "Event Types",
      dataIndex: "event_name",
      filterIndex: "event_name",
      key: "event_name",
    },

    {
      title: "Date & Time",
      dataIndex: "convert_created_at",
      filterIndex: "convert_created_at",
      key: "convert_created_at",
      render: (e) => {
        return <span>{e}</span>;
      },
    },
    {
      title: "Logged In",
      dataIndex: "logged_in_date_time",
      filterIndex: "logged_in_date_time",
      key: "logged_in_date_time",
    },
    {
      title: "Logged Out",
      dataIndex: "logged_out_date_time",
      filterIndex: "logged_out_date_time",
      key: "logged_out_date_time",
    },

    {
      title: "Time Spent",
      dataIndex: "time_spent",
      filterIndex: "time_spent",
      key: "time_spent",
    },

    {
      title: "Updated At",
      key: "convert_updated_at",
      filterIndex: "convert_updated_at",
      dataIndex: "convert_updated_at",
    },
  ];

  useEffect(() => {
    setLoading(true);
    GetList();
    return () => {};
  }, []);

  return (
    <Table2
      columns={columns?.map((d) => ({
        ...d,

        ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
      }))}
      onChange={handleChange}
      loading={loading}
      dataSource={list}
      className="mt-5"
      bordered
      pagination={PaginationConfig({
        pageSize,
        page,
        totalPage,
        onChange: (e, f) => {
          setPageSize(f);
          setPage(e);
          GetList({
            page: e,
            pageSize: f,
            ...sortFilters,
          });
        },
      })}
      scroll={{ x: "max-content" }}
    />
  );
};

export default UserLogs;

import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Checkbox, Input, message, Popover, Switch, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Table2 from "../../../../../components/tables/table2";
import { EditIcon, isNot2000SeriesYear } from "../../../../../config";

const AdvertisementCampaignList = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [marketplaceObj, setMarketplaceObj] = useState({
    currency_sign: "₹",
    marketplace_id: "",
  });

  const [isAllCampaign, setIsAllCampaign] = useState(false);
  const [updatingCampaignStatus, setUpdatingCampaignStatus] = useState(false);
  const [isOpenEditCampaign, setIsOpenEditCampaign] = useState(false);
  const [editCampaignData, setEditCampaignData] = useState({});

  const getList = async () => {
    const response = await MakeApiCall(
      `advertising/get-all-campaigns-list?no_pagination=true&marketplace_id=A21TJRUUN4KGV`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.table_data || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {};
  }, []);

  const SaveCampaignStatus = async (state, campaignList) => {
    const response = await MakeApiCall(
      `advertising/update-bulk-campaign`,
      `put`,
      {
        ids: campaignList,
        campaign_status: state,
      },
      true
    );
    if (response?.status) {
      getList();
      message.destroy();
      message.success(response?.message);
    } else {
      getList();
      message.destroy();
      message.error(response?.message);
    }
  };

  const columns = [
    {
      title: () => {
        return (
          <Checkbox
            checked={isAllCampaign}
            onChange={(e) => {
              if (e.target.checked) {
                const allCamp = list?.map((d) => d?.campaign_id);
                setSelectedCampaign(allCamp);
              } else {
                setSelectedCampaign([]);
              }
              setIsAllCampaign(e.target.checked);
            }}
          />
        );
      },
      ellipsis: true,
      align: "left",
      fixed: "left",
      width: 80,
      render: (text) => {
        return (
          <>
            <Checkbox
              checked={
                selectedCampaign.filter((a) => a == text?.campaign_id)
                  ?.length == 1
                  ? true
                  : false
              }
              size="large"
              onChange={(e) => {
                const checkboxId = text?.campaign_id;
                setSelectedCampaign((prev) => {
                  if (e?.target?.checked) {
                    return [...prev, checkboxId];
                  } else {
                    return prev.filter(
                      (campaign_id) => campaign_id !== checkboxId
                    );
                  }
                });
              }}
            />
            <Switch
              disabled={updatingCampaignStatus}
              className="ms-2"
              size="small"
              checked={text?.state === "ENABLED" ? true : false}
              onChange={(e) => {
                setUpdatingCampaignStatus(true);
                SaveCampaignStatus(e ? "ENABLED" : "PAUSED", [
                  text?.campaign_id,
                ]);
                setList((prev) => {
                  return prev.map((item) => {
                    if (item?.campaign_id === text?.campaign_id) {
                      return {
                        ...item,
                        state: text?.state === "ENABLED" ? "PAUSED" : "ENABLED",
                      };
                    }
                    return item;
                  });
                });
              }}
            />
          </>
        );
      },
    },
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      render: (text) => {
        return !text ? (
          "-"
        ) : (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "600px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              className="text-dark mb-1 fw-bolder singleLineEllipsis fs-6"
              style={{ width: "150px" }}
            >
              {text || "-"}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Campaign Type",
      // dataIndex: "campaignType",
      // key: "campaignType",
      dataIndex: "ad_type",
      key: "ad_type",
      width: 110,
      localSort: true,
      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={
              text === "SPONSORED_PRODUCTS"
                ? "gold"
                : text === "SPONSORED_DISPLAY"
                ? "green"
                : text === "SPONSORED_BRAND"
                ? "geekblue"
                : "purple"
            }
            style={{ textTransform: "capitalize" }}
            // bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Targeting Type",
      // dataIndex: "targetingType",
      // key: "targetingType",
      dataIndex: "targeting_settings",
      key: "targeting_settings",
      width: 140,
      localSort: true,
      render: (text) => {
        return (
          <Tag
            className="fw-bolder"
            color={
              text === "MANUAL" ? "magenta" : text === "AUTO" ? "blue" : ""
            }
            style={{ textTransform: "capitalize" }}
            bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      // key: "status",
      dataIndex: "state",
      key: "state",
      width: 110,
      localSort: true,
      render: (text, _) => {
        return (
          <>
            <Tag
              className="fw-bolder"
              color={
                text === "Proposal"
                  ? "blue"
                  : text === "ENABLED"
                  ? "green"
                  : text === "PAUSED"
                  ? "orange"
                  : text === "COMPLETED"
                  ? "purple"
                  : "error"
              }
            >
              {text}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Budget Type",
      // dataIndex: "budgetType",
      // key: "budgetType",
      dataIndex: "budget_type",
      key: "budget_type",
      width: 130,
      localSort: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      // dataIndex: "startDate",
      // key: "startDate",
      width: 120,
      localSort: true,
    },
    {
      title: "End Date  ",
      // dataIndex: "endDate",
      // key: "endDate",
      dataIndex: "end_date",
      key: "end_date",
      width: 110,
      localSort: true,
      render: (text) => {
        const year = isNot2000SeriesYear(text);
        return <label>{year ? "No End Date" : text}</label>;
      },
    },
    {
      title: "Total Budget",
      // dataIndex: "totalBudget",
      // key: "totalBudget",
      dataIndex: "budget_amount",
      key: "budget_amount",
      width: 120,
      localSort: true,
      render: (text) => {
        return (
          <span className="">
            {marketplaceObj?.currency_sign}
            {text}
          </span>
        );
      },
    },

    // {
    //   title: "Cost Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (text) => {
    //     return <span>{text || "-"}</span>;
    //   },
    // },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: 115,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: 80,
      localSort: true,
      render: (text) => {
        return (
          <span>
            {marketplaceObj?.currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    // {
    //   title: "Orders",
    //   dataIndex: "unit_ordered",
    //   key: "unit_ordered",
    //   width: 100,
    //   render: (text) => {
    //     return <span>{ text || "0"}</span>;
    //   },
    // },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      width: 80,
      localSort: true,
      render: (text) => {
        return (
          <span>
            {marketplaceObj?.currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
    },
    {
      title: "RoAS",
      dataIndex: "ROAS",
      key: "ROAS",
      width: 80,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "NTB Orders",
      dataIndex: "new_to_brand_orders",
      key: "new_to_brand_orders",
      width: 120,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Units Sold",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
      width: 110,
      localSort: true,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    // {
    //   title: "Daily Budget",
    //   // dataIndex: "dailyBudget",
    //   // key: "dailyBudget",
    //   dataIndex: "daily_limit",
    //   key: "daily_limit",
    //   render: (text) => {
    //     return <span className="">{marketplaceObj?.currency_sign}{text}</span>;
    //   },
    // },

    // {
    //   title: "Campaign ID",
    //   // dataIndex: "campaignId",
    //   // key: "campaignId",
    //   dataIndex: "campaign_id",
    //   key: "campaign_id",
    // },
    // {
    //   title: "Bidding Strategy",
    //   dataIndex: "bidding_strategy",
    //   key: "bidding_strategy",
    //   render: (biddingStrategy) => {
    //     const data = JSON.parse(biddingStrategy);
    //     return data?.strategy;
    //   },
    // },
    // {
    //   title: "Channel",
    //   dataIndex: "channel",
    //   key: "channel",
    // },
    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   sorter: (a, b) => (a.created_at || 0) - (b.created_at || 0),
    //   render: (e) => {
    //     return (
    //       <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
    //     );
    //   },
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   sorter: (a, b) => a.updated_at || 0 - b.updated_at || 0,
    //   render: (e) => {
    //     return (
    //       <>{dayjs(new Date(e * 1000)).format(`${DateFormate} hh:mm A`)}</>
    //     );
    //   },
    // },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      render: (text) => {
        return (
          <div>
            <Button
              onClick={() => {
                setEditCampaignData(text);
                setIsOpenEditCampaign(true);
              }}
              type="link"
            >
              <EditIcon />
            </Button>
            {/* <Popconfirm
              placement="left"
              title="Are you sure you want to delete?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
            
              }}
            >
              <Button type="link">
                <Icon
                  icon="fluent:delete-20-filled"
                  style={{ color: "#ff5454" }}
                  width={24}
                />
              </Button>
            </Popconfirm> */}
          </div>
        );
      },
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearchKey("");
    getList({ searchKey: "" });
  };

  return (
    <>
      <div className="card  mb-5 mb-xl-8">
        <div className="card-header  py-2 px-3">
          <div className="card-title">
            <Input
              onChange={(e) => {
                setSearchKey((prev) => {
                  if (!e.target.value && prev) {
                    ClearSearch();
                  }
                  return e.target.value;
                });
              }}
              onPressEnter={() => {
                setLoading(true);
                getList({ searchKey: searchKey });
              }}
              value={searchKey}
              placeholder="Search by Campaign name"
              prefix={<Icon icon="clarity:search-line" />}
            />
          </div>
          <div className="card-toolbar"></div>
        </div>

        <div className="card-body  px-3">
          <Table2
            columns={columns}
            // pagination={PaginationConfig({
            //   pageSize,
            //   page,
            //   totalPage,
            //   onChange: (e, f) => {
            //     setLoading(true);
            //     setPageSize(f);
            //     setPage(e);
            //     getList({
            //       page: e,
            //       pageSize: f,
            //       searchKey: searchKey,
            //     });
            //   },
            // })}
            dataSource={list}
            loading={loading}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </>
  );
};

export default AdvertisementCampaignList;

import { Button, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";

const EnterList = (props) => {
  const { getAsinList } = props;
  const [enterListValue, setEnterListValue] = useState("");

  return (
    <Row>
      <Col span={24}>
        <TextArea
          onChange={(e) => {
            setEnterListValue(e.target.value);
          }}
          value={enterListValue}
          cols={30}
          rows={8}
          placeholder="Enter ASIN's separated by comma"
        />
      </Col>
      <Col span={24} className="mt-5">
        <Button
          type="primary"
          onClick={() => {
            getAsinList(enterListValue);
          }}
        >
          Add
        </Button>
      </Col>
    </Row>
  );
};

export default EnterList;

import { Table, message } from "antd";

import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import dayjs from "dayjs";
import Table2 from "../../../../components/tables/table2";

const EventTypes = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "ID",

      align: "center",
      render: (text, record, index) => (1 - 1) * list?.length + 1 + index,
    },
    {
      title: "Event",
      dataIndex: "event_name",
      key: "event_name",
      sorter: (a, b) => a.event_name.localeCompare(b.event_name),
    },
    {
      title: "Created At (ET)",
      align: "left",
      sorter: (a, b) => {
        // Assuming time_spent is a duration in a comparable format (e.g., minutes or seconds)
        return a.created_at - b.created_at;
      },
      render: (d) => {
        return (
          <div>
            {dayjs(new Date(parseInt(d.created_at) * 1000)).format(
              "MM/DD/YYYY hh:mm A"
            )}
          </div>
        );
      },
    },

    {
      title: "Updated At (ET)",
      align: "left",
      sorter: (a, b) => {
        // Assuming time_spent is a duration in a comparable format (e.g., minutes or seconds)
        return a.updated_at - b.updated_at;
      },
      render: (d) => {
        return (
          <div>
            {dayjs(new Date(parseInt(d.updated_at) * 1000)).format(
              "MM/DD/YYYY hh:mm A"
            )}
          </div>
        );
      },
    },
  ];
  const GetList = async (data) => {
    const response = await MakeApiCall(
      `seller-activity/event-types`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };
  useEffect(() => {
    setLoading(true);
    GetList();
    return () => {};
  }, []);

  return (
    <Table2
      dataSource={list}
      loading={loading}
      columns={columns}
      bordered
      className="mt-5"
      pagination={false}
      scroll={{ x: "max-content" }}
    />
  );
};

export default EventTypes;

import { Button, Col, Divider, message, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../../../../../../apis";
import { PlusIcon } from "../../../../../../../../../../config";
const { Text } = Typography;

const SearchItem = (props) => {
  const { marketplaceId, selectedKeywordList, setSelectedKeywordList } = props;
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [suggKeywordList, setSuggKeywordList] = useState([]);

  useEffect(() => {
    const data = {
      marketplace_id: marketplaceId,
      bidding_strategy: "LEGACY_FOR_SALES",
      asins: [
        "B0D2PGXJ73",
        "B0CWQJWRB9",
        "B0995Z5CFQ",
        "B08WS387SM",
        "B08CHDM8L5",
        "B08BWRBY93",
      ],
      sort_dimension: "DEFAULT",
    };
    setKeywordLoading(true);
    GetSuggestedKeyword(data);
  }, []);

  const GetSuggestedKeyword = async (data) => {
    const response = await MakeApiCall(
      `advertising/get-keyword-targeting-suggestions`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      const transformedData = response?.data.map((item) => {
        const { bid_info, ...rest } = item;
        return { ...rest, ...bid_info };
      });
      setSuggKeywordList(transformedData);
    } else {
      setSuggKeywordList([]);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Button type="primary">Add All Keywords</Button>
        </Col>
      </Row>
      <Row style={{ overflow: "auto", height: "300px" }} className="mt-4">
        {suggKeywordList.length > 0 &&
          suggKeywordList?.map((item) => {
            const isAdd = selectedKeywordList?.filter(
              (f) => f.keyword_name === item?.keyword
            );
            return (
              <Col span={24} className="mt-5">
                <Row>
                  <Col span={24}>
                    <div className="d-flex">
                      <Text type="secondary" className="me-1">
                        Keyword:
                      </Text>
                      <Text strong>{item?.keyword}</Text>
                    </div>
                  </Col>
                </Row>
                <Divider className="my-1" />
                <Row>
                  <Col span={9}>
                    <Text type="secondary">Match Type:</Text>
                    <br />
                    <Text type="secondary">Sugg. Bid:</Text>
                  </Col>
                  <Col span={5}>
                    <Text strong>Broad</Text> <br />
                    <div className="d-flex align-items-center">
                      <Text strong>{item?.BROAD?.suggested_bid || 0}</Text>
                      {!isAdd?.[0]?.broad?.name && (
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            const newObj = {
                              keyword_name: item?.keyword,
                              broad: {
                                keyword_name: item?.keyword,
                                name: "BROAD",
                                old_sugg_bid: item?.BROAD?.suggested_bid || 0,
                                sugg_bid: "suggested_bid",
                                // bid_selection === "suggested_bid"
                                //   ? d?.BROAD?.suggested_bid
                                //   : common_default_bid,
                                state: "ENABLED",
                              },
                            };
                            setSelectedKeywordList((prev) => {
                              const existingKeyword = prev?.find(
                                (sk) => sk?.keyword_name === item?.keyword
                              );
                              if (existingKeyword) {
                                // Update existing keyword
                                return prev.map((sk) =>
                                  sk?.keyword_name === item?.keyword
                                    ? {
                                        ...sk,
                                        ...newObj,
                                      }
                                    : sk
                                );
                              } else {
                                return [...prev, newObj];
                              }
                            });
                          }}
                        >
                          <PlusIcon width="17" />
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col span={5}>
                    <Text strong>Phrase</Text> <br />
                    <div className="d-flex align-items-center">
                      <Text strong>{item?.PHRASE?.suggested_bid || 0}</Text>
                      <PlusIcon width="17" />
                    </div>
                  </Col>
                  <Col span={5}>
                    <Text strong>Exact</Text> <br />
                    <div className="d-flex align-items-center">
                      <Text strong>{item?.EXACT?.suggested_bid || 0}</Text>
                      <PlusIcon width="17" />
                    </div>
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default SearchItem;

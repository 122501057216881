import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import LogoAnimation from "../components/logo-animation";
import Page from "../modules/page";
import { setData, setSessionData } from "../redux/actions";
import ProtectedRoute from "./ProtectedRoute";
import { Admins } from "./admin";
import { Auth } from "./auth";
import { Users } from "./user";
import { Spin } from "antd";
import { DefaultAdminPage, DefaultUserPage } from "../config";

export default function Routers({ loading }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const session = useSelector((state) => state.session.data);
  const loginType = user?.loginType;

  const pages = {
    admin: Admins,
    user: Users,
  };

  const findRoutes = {
    admin: DefaultAdminPage,
    user: DefaultUserPage,
  };

  console.log(loginType, "loginType");

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Navigate to={loginType ? findRoutes?.[loginType] : "/sign-in"} />
      ),
    },
    ...Auth,
    ...(loginType
      ? [
          {
            path: "/",
            element: (
              <Suspense fallback={<LogoAnimation />}>
                <Page
                  user={user}
                  setData={(e) => dispatch(setData(e))}
                  setSessionData={(e) => dispatch(setSessionData(e))}
                  session={session}
                />
              </Suspense>
            ),
            children: (pages[loginType] || []).map((d, i) => ({
              path: d.path,
              element: (
                <ProtectedRoute
                  user={user}
                  setData={(e) => dispatch(setData(e))}
                  setSessionData={(e) => dispatch(setSessionData(e))}
                  session={session}
                  element={d.element}
                />
              ),
            })),
          },
          {
            path: "*",
            element: <Navigate to="/" />,
          },
        ]
      : []),
    {
      path: "*",
      element: <Navigate to="/sign-in" />,
    },
  ]);

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

import { Button, Input, Form, Col, Row } from "antd";
import React, { useRef, useEffect, useState } from "react";

function generateCaptchaText() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
function drawCaptcha(ctx, text) {
  const width = 200;
  const height = 50;
  ctx.clearRect(0, 0, width, height);
  ctx.fillStyle = "#f3f3f3";
  ctx.fillRect(0, 0, width, height);

  // Draw each character with a random font size and rotation
  const chars = text.split("");
  const baseX = 50;
  const baseY = height / 2;
  let currentX = baseX;

  chars.forEach((char) => {
    const fontSize = Math.random() * 10 + 30; // Font size between 18 and 28
    ctx.font = `${fontSize}px ui-serif`;
    ctx.fontWeight = "800";
    ctx.fillStyle = "#065ad8";

    ctx.textBaseline = "middle";

    // Random rotation between -15 and 15 degrees
    const rotation = Math.random() * 30 - 15;
    ctx.save();
    ctx.translate(currentX, baseY);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.fillText(char, 0, 0);
    ctx.restore();

    // Increment X position for next character
    currentX += ctx.measureText(char).width + 2;
  });

  // Draw noisy lines
  for (let i = 0; i < 20; i++) {
    ctx.beginPath();
    ctx.moveTo(Math.random() * width, Math.random() * height);
    ctx.lineTo(Math.random() * width, Math.random() * height);
    ctx.strokeStyle = `#065ad898`;
    ctx.stroke();
  }
}

const Captcha = ({ setCaptchaStatus, reloadCode, form }) => {
  const canvasRef = useRef(null);
  const [captcha, setCaptcha] = useState(generateCaptchaText());
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    drawCaptcha(ctx, captcha);
  }, [captcha]);

  const refreshCaptcha = () => {
    setCaptcha(generateCaptchaText());
    setUserInput(""); // Clear user input on refresh
    form.setFieldsValue({
      captcha: "",
    });
  };

  useEffect(() => {
    refreshCaptcha();
  }, [reloadCode]);
  const handleInputChange = (e) => {
    setUserInput(e.target.value);

    setCaptchaStatus(e.target.value?.toLowerCase() === captcha?.toLowerCase());
  };

  return (
    <div>
      <Row>
        <Col
          span={24}
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: "10px",
          }}
        >
          <canvas
            ref={canvasRef}
            width={200}
            height={50}
            style={{ border: "1px solid #d3d3d3" }}
          />
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            size="large"
            onClick={refreshCaptcha}
          >
            <svg
              width={18}
              height={18}
              fill="#ffffff"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3.337 12c0-4.348 3.388-8.663 8.663-8.663 4.26 0 6.366 3.153 7.263 4.83H16.6a.767.767 0 0 0 0 1.533h4.6a.767.767 0 0 0 .767-.767v-4.6a.767.767 0 0 0-1.534 0v2.78c-1.08-1.904-3.579-5.31-8.433-5.31C5.77 1.803 1.803 6.917 1.803 12c0 5.082 3.967 10.197 10.197 10.197 2.98 0 5.462-1.18 7.229-2.977.946-.963 1.685-2.1 2.188-3.324A.767.767 0 1 0 20 15.312a8.846 8.846 0 0 1-1.864 2.833c-1.49 1.516-3.582 2.518-6.135 2.518-5.275 0-8.663-4.316-8.663-8.663Z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter your Captcha!",
              },
              () => ({
                validator: async (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (value?.toLowerCase() === captcha?.toLowerCase()) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error("Invalid Captcha, please try again!")
                    );
                  }
                },
              }),
            ]}
            name="captcha"
          >
            <Input
              value={userInput}
              onChange={handleInputChange}
              placeholder="Enter CAPTCHA"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Captcha;

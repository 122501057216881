// store.js

import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
// import { ProjectName } from "../config";
import sessionStorage from "redux-persist/es/storage/session";

const encryptor = encryptTransform({
  secretKey: "Aalacart", // Use a strong secret key
  onError: function (error) {
    console.error("Encryption error:", error);
  },
});

const localPersistConfig = {
  key: "local",
  storage,
  transforms: [encryptor],
};

const sessionPersistConfig = {
  key: "session",
  storage: sessionStorage,
  transforms: [encryptor],
};

const userReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

const sessionReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "SESSION_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

const localPersistedReducer = persistReducer(localPersistConfig, userReducer);
const sessionPersistedReducer = persistReducer(
  sessionPersistConfig,
  sessionReducer
);

const rootReducer = combineReducers({
  user: localPersistedReducer,
  session: sessionPersistedReducer,
});

// Store and persistor
const store = createStore(rootReducer);
const persistor = persistStore(store);

export { store, persistor };

import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-table-wrapper .ant-table-tbody > .ant-table-row > .ant-table-cell {
    padding: 16px 23px !important;
  }
  .ant-table-wrapper .ant-table-thead > tr > .ant-table-cell {
    padding: 16px 23px !important;
  }
  .ant-table-expanded-row {
    .ant-table-cell {
      padding-left: 7px !important;
    }
    .ant-table-scroll-horizontal {
      margin-left: 0px !important;
    }
  }
  button.ant-btn span {
    font-size: 15px;
    line-height: 0;
    display: flex;
  }
`;
